
import { CSSProperties, useRef, useState } from 'react';
import { MsnRes } from '../../API/ResponseModels/Messenger';
import ChatMessageReactionSuggested from '../../components/Chat/ChatMessageReactionSuggested';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import './ChatMessageReactionsSuggested.scss'
import useWindowOnClick from '../../components/_hooks/useWindowOnClick';
import useCurrentWidth from '../../components/_hooks/useCurrentWidth';
import { useLayoutEffect } from 'react';

interface IProps {
    reactions: MsnRes.IReactionType[],
    message: MsnRes.IMessage,
}

const ChatMessageReactionsSuggested = ({ reactions, message }: IProps) => {
    const [reactionsVisible, setReactionsVisible] = useState<boolean>(false);
    const width = useCurrentWidth();
    const reactionsDiv = useRef<HTMLDivElement>(null)
    const [reactionsDivStyle, setReactionsDivStyle] = useState<CSSProperties | undefined>()

    useLayoutEffect(() => {
        if (reactionsVisible && reactionsDiv && reactionsDiv.current) {
            let calcWidth = reactions.length * 49.06;
            let rect = reactionsDiv.current.getBoundingClientRect();
            let chatMessages = document.getElementById("chat-messages");
            if (chatMessages) {
                let chatMessagesWidth = chatMessages.clientWidth;
                let chatMessagesRect = chatMessages.getBoundingClientRect();
                let offsetRight = chatMessagesRect.right - rect.right;
                if (calcWidth > (chatMessagesWidth - offsetRight)) {
                    setReactionsDivStyle({
                        width: width - (width - (chatMessagesWidth - offsetRight)),
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row'
                    });
                }
                else {
                    setReactionsDivStyle(undefined);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, reactionsDiv]);

    useWindowOnClick(
        (event: any) => {
            let elem = event.target;
            let show = false;
            if (elem.id !== "chat-message-reactions-suggested-wrapper") {
                while (elem.parentElement) {
                    elem = elem.parentElement;
                    if (elem.id === "chat-message-reactions-suggested-wrapper") {
                        show = true;
                        break;
                    }
                }
            } else {
                show = true;
            }
            if (!show) {
                setReactionsVisible(false)
            }
        }
    )

    return (
        <div id="chat-message-reactions-suggested-wrapper" className="chat-message-reactions-suggested-wrapper">
            <div>
                <FontAwesomeIcon className="chat-message-reactions-suggested__button cursor-pointer" icon={faSmile}
                    onClick={() => setReactionsVisible(!reactionsVisible)} />
            </div>
            {
                reactionsVisible &&
                <div ref={reactionsDiv} className="chat-message-reactions-suggested" style={reactionsDivStyle}>
                    {
                        reactions.map(r => {
                            return (
                                <ChatMessageReactionSuggested key={r.messengerReactionTypeID} reaction={r} message={message} />
                            )
                        })
                    }
                </div>
            }
        </div>
    );
}

export default ChatMessageReactionsSuggested;