import { HubConnection } from "@microsoft/signalr";
import { MessengerConversationTypeID } from "../API/Enum";
import MPAApi from "../API/MPAApi";
import { MsnRes } from "../API/ResponseModels/Messenger";
import { ResourcesRes } from "../API/ResponseModels/Resources";
import { WsRes } from "../API/ResponseModels/WebSocket";

declare global {
    interface Window {
        debug: boolean,
        logRender: boolean,
        previewImage: Function,
        selectReaction: Function,
        websocket: HubConnection,
        updateConversationPref: Function,
        // Websocket command:
        addConversation: Function,
        updateConversation: Function,
        updateConversationList: Function,
        updateChatMessages: Function,
        deleteChatMessage: Function,
        updateConversationListOnDeleteMessage: Function,
        updateUserStatus: Function,
        newReaction: Function,
        deleteReaction: Function,
        banUser: Function,
        unbanUser: Function,
    }
}

// Utils:
export const getPreviewImage = (conversation: MsnRes.IConversation, memberGuid: string, userList: MsnRes.IUser[], images: ResourcesRes.IBase64[]) => {
    let checkForPicture = conversation.messengerConversationTypeID === MessengerConversationTypeID.OneToOne;
    let image: ResourcesRes.IBase64 | undefined;
    if (checkForPicture) {
        // Retrive the other user of the current OneToOne chat:
        let otherMemberGuid: string;
        if (conversation.firstMemberGuid === memberGuid) {
            otherMemberGuid = conversation.secondMemberGuid;
        } else {
            otherMemberGuid = conversation.firstMemberGuid;
        }
        let user = userList.find(y => y.memberGuid === otherMemberGuid);
        if (user) {
            image = images.find(y => y.resourceID === user?.userProfilePicture);
        }
    } else {
        image = images.find(y => y.resourceID === conversation.previewImageResourceID);
    }
    return image;
}

export const getConversationTitle = (conversation: MsnRes.IConversation, memberGuid: string, userList: MsnRes.IUser[]) => {
    if (conversation.messengerConversationTypeID === MessengerConversationTypeID.OneToOne) {
        // Retrive the other user of the current OneToOne chat:
        let otherMemberGuid: string;
        if (conversation.firstMemberGuid === memberGuid) {
            otherMemberGuid = conversation.secondMemberGuid;
        } else {
            otherMemberGuid = conversation.firstMemberGuid;
        }
        let user = userList.find(y => y.memberGuid === otherMemberGuid);
        if (user) {
            return user.userFirstName + " " + user.userLastName;
        }
    }
    return conversation.conversationTitle;
}

export const checkConversationHidden = (conversation: MsnRes.IConversation) => {
    let hidden = true;
    if (!conversation.conversationHidden || (conversation.lastMessageDate && conversation.conversationHidden < conversation.lastMessageDate)) {
        hidden = false;
    }
    return hidden;
}

export const getOnlineStatus = (conversation: MsnRes.IConversation, memberGuid: string, userList: MsnRes.IUser[]) => {
    if (conversation.messengerConversationTypeID === MessengerConversationTypeID.OneToOne) {
        // Retrive the other user of the current OneToOne chat:
        let otherMemberGuid: string;
        if (conversation.firstMemberGuid === memberGuid) {
            otherMemberGuid = conversation.secondMemberGuid;
        } else {
            otherMemberGuid = conversation.firstMemberGuid;
        }
        let user = userList.find(y => y.memberGuid === otherMemberGuid);
        if (user) {
            return user.online;
        }
    }
    return false;
}

export const updateConversationListWebSocket = (conversationList: MsnRes.IConversation[], message: WsRes.IWsMessage, activeConversationID: string) => {
    let index = conversationList.findIndex(x => x.messengerConversationID === message.conversationID);
    if (index > -1) {
        conversationList[index].lastMessageID = message.messageID;
        conversationList[index].lastMessage = message.message;
        conversationList[index].lastMessageDate = message.timestamp;
        if (activeConversationID !== conversationList[index].messengerConversationID) {
            if (isNaN(conversationList[index].newMessagesNumber)) {
                conversationList[index].newMessagesNumber = 0;
            }
            conversationList[index].newMessagesNumber += 1;
        }
    }
    return conversationList;
}

export const handlePin = (conversation?: MsnRes.IConversation) => {
    if (conversation) {
        if (conversation.pinned) {
            MPAApi.Messenger.unPinConversation(conversation.messengerConversationID).then(res => {
                const response = res.data;
                if (response.status === 'success' && window.updateConversationPref) {
                    window.updateConversationPref(conversation.messengerConversationID, false, conversation.conversationHidden);
                }
            }).catch(e => console.error("unPinConversation - error", e));
        }
        else {
            MPAApi.Messenger.pinConversation(conversation.messengerConversationID).then(res => {
                const response = res.data;
                if (response.status === 'success' && window.updateConversationPref) {
                    window.updateConversationPref(conversation.messengerConversationID, true, conversation.conversationHidden);
                }
            }).catch(e => console.error("pinConversation - error", e));
        }
    }
}

export const handleHide = (conversation?: MsnRes.IConversation) => {
    if (conversation) {
        if (conversation.conversationHidden) {
            MPAApi.Messenger.showConversation(conversation.messengerConversationID).then(res => {
                const response = res.data;
                if (response.status === 'success' && window.updateConversationPref) {
                    window.updateConversationPref(conversation.messengerConversationID, conversation.pinned, undefined);
                }
            }).catch(e => console.error("showConversation - error", e));
        }
        else {
            MPAApi.Messenger.hideConversation(conversation.messengerConversationID).then(res => {
                const response = res.data;
                if (response.status === 'success' && window.updateConversationPref) {
                    window.updateConversationPref(conversation.messengerConversationID, conversation.pinned, new Date());
                }
            }).catch(e => console.error("hideConversation - error", e));
        }
    }
}

export const handleBlock = (conversation?: MsnRes.IConversation) => {
    if (conversation) {
        if (!conversation.isBlocked) {
            MPAApi.Messenger.blockConversation(conversation.messengerConversationID)
                .then(res => { })
                .catch(e => console.error("blockConversation - error", e));
        }
        else {
            MPAApi.Messenger.unBlockConversation(conversation.messengerConversationID)
                .then(res => { })
                .catch(e => console.error("unBlockConversation - error", e));
        }
    }
}

// Transform object with properties in query string
export const objToQueryParams = (obj: { [key: string]: any }) => {
    const keys = Object.keys(obj);
    let query = '?'
    keys.forEach(key => {
        query += `${key}=${obj[key]}&`;
    })
    return query.slice(0, -1);
}

// Storage: 
class StorageUtilsClass {
    SetItem = (key: string, value: any, isPermanent?: boolean) => {
        var timestamp = new Date().getTime();
        if (key === 'indexedDBRef' || isPermanent) {
            timestamp = new Date('2999-01-01').getTime()
        }
        try {
            localStorage.setItem(key, JSON.stringify({ value: value, timestamp: timestamp }));
        } catch (e) {
            // Quota exceeded: remove oldest API call saved and try again
            //this.storageDeleteOldestItem();
            this.SetItem(key, value);
        }
    }

    GetItem = (key: string) => {
        if (localStorage.getItem(key)) {
            try {
                return JSON.parse(localStorage.getItem(key) || '[]');
            } catch (e) {
                return localStorage.getItem(key);
            }
        } else {
            return null
        }

    }

    GetItemValue = (key: string) => {
        const item = this.GetItem(key);
        if (item === null) return null;
        if (item.value === undefined) return item;
        try {
            return JSON.parse(item.value);
        } catch (e) {
            return item.value;
        }

    }

    RemoveItem = (key: string) => {
        localStorage.removeItem(key);
    }
}
class SessionStorageUtilsClass {
    SetItem = (key: string, value: any, isPermanent?: boolean) => {
        var timestamp = new Date().getTime();
        if (key === 'indexedDBRef' || isPermanent) {
            timestamp = new Date('2999-01-01').getTime()
        }
        try {
            sessionStorage.setItem(key, JSON.stringify({ value: value, timestamp: timestamp }));
        } catch (e) {
            // Quota exceeded: remove oldest API call saved and try again
            //this.storageDeleteOldestItem();
            this.SetItem(key, value);
        }
    }

    GetItem = (key: string) => {
        if (sessionStorage.getItem(key)) {
            try {
                return JSON.parse(sessionStorage.getItem(key) || '[]');
            } catch (e) {
                return sessionStorage.getItem(key);
            }
        } else {
            return null
        }

    }

    GetItemValue = (key: string) => {
        const item = this.GetItem(key);
        if (item === null) return null;
        if (item.value === undefined) return item;
        try {
            return JSON.parse(item.value);
        } catch (e) {
            return item.value;
        }

    }

    RemoveItem = (key: string) => {
        localStorage.removeItem(key);
    }
}
export const StorageUtils = new StorageUtilsClass();
export const SessionStorageUtils = new SessionStorageUtilsClass();