import { useContext, useEffect, useRef, useState } from "react";
import ChatMessage from "../../components/Chat/ChatMessage";
import './ChatMessages.scss'
import MPAApi from "../../API/MPAApi";
import { MsnRes } from "../../API/ResponseModels/Messenger";
import { MsnReq } from "../../API/RequestModels/Messenger";
import { ResourcesRes } from "../../API/ResponseModels/Resources";
import ChatUserInfo from "../../components/Chat/ChatUserInfo";
import { ContextAppSettings } from "../../config/context";
import { WsRes } from "../../API/ResponseModels/WebSocket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import useScroll from "../../components/_hooks/useScroll";
import { animated, useSpring } from "react-spring";
import useCurrentWidth from "../../components/_hooks/useCurrentWidth";
import { cloneDeep } from "lodash";
import useWindowOnClick from "../../components/_hooks/useWindowOnClick";

interface IProps {
    userList: MsnRes.IUser[],
    images: ResourcesRes.IBase64[],
    conversationID: string,
    handleQuoteMessage: Function,
    showPanel: boolean,
}

export interface ISelectedReaction {
    reactionID: number,
    messageID: number,
}

let timeout: NodeJS.Timeout;
const ChatMessages = ({ userList, images, conversationID, handleQuoteMessage, showPanel }: IProps) => {
    const { memberGuid } = useContext(ContextAppSettings);
    const [messageClicked, setMessageClicked] = useState<MsnRes.IMessage>();
    const [messages, setMessages] = useState<MsnRes.IMessage[]>([]);
    const [selectedReaction, setSelectedReaction] = useState<ISelectedReaction>({ reactionID: 0, messageID: 0 });
    // User info:
    const [showUserInfo, setShowUserInfo] = useState<boolean>(false);
    const [pictureUserInfo, setPictureUserInfo] = useState<string>("");
    // Scroll:
    const wrapper = useRef<HTMLHeadingElement>(null);
    const scroll = useScroll(wrapper.current);
    // Virtual scrolling:
    const isLoading = useRef<boolean>(true);
    const isLoadingPrevMessages = useRef<boolean>(false);
    const [loadingPrevMessages, setLoadingPrevMessages] = useState<boolean>(false);
    const numTotalMessage = useRef<number | null>(null);
    const numReadMessage = useRef<number>(0);
    const offset = useRef<number>(0);
    const virtualScrollDummy = useRef<HTMLHeadingElement>(null);
    // Scroll to last message:
    const dummy = useRef<HTMLHeadingElement>(null);
    const [showScrollToLastMessage, setShowScrollToLastMesage] = useState<boolean>(false);
    const [lastMessageIndex, setLastMessageIndex] = useState(-1);
    const width = useCurrentWidth();
    let isSmallDevice = width < 767.98;
    const isMounted = useRef<boolean>(true);

    useEffect(() => {
        window.updateChatMessages = (message: WsRes.IWsMessage) => updateChatMessages(message);
        window.deleteChatMessage = (messageID: string) => deleteChatMessage(parseInt(messageID));
        window.newReaction = (reaction: MsnReq.IReaction) => newReaction(reaction);
        window.deleteReaction = (reactionID: string) => deleteReaction(parseInt(reactionID));
        window.selectReaction = (messageID: number, reactionID: number) => setSelectedReaction({ messageID: messageID, reactionID: reactionID });

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
            isMounted.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const virtualDummyOffset = virtualScrollDummy.current?.offsetTop || -1;
        if (scroll < virtualDummyOffset) {
            loadPreviousMessages();
        }
        const lastMessageOffset = -100;
        if (scroll < lastMessageOffset) {
            setShowScrollToLastMesage(true);
        } else {
            setShowScrollToLastMesage(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scroll])

    useEffect(() => {
        if (conversationID && !conversationID.startsWith("conv_")) {
            let req: MsnReq.IAllMessagesReq = getRequest();
            getAllMessages(req);
        } else {
            setMessages([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationID])

    useEffect(() => {
        if (loadingPrevMessages) {
            timeout = setTimeout(() => {
                let req: MsnReq.IAllMessagesReq = getRequest();
                getAllMessages(req);
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingPrevMessages]);

    const loadPreviousMessages = () => {
        let _numTotalMessage = numTotalMessage?.current || 0;
        let _numReadMessage = numReadMessage?.current || 0;
        if (!isLoading.current && !isLoadingPrevMessages.current &&
            (numTotalMessage.current === null || (_numTotalMessage > _numReadMessage))) {
            isLoadingPrevMessages.current = true;
            setLoadingPrevMessages(true);
        } else {
            if (_numTotalMessage === _numReadMessage) {
                isLoadingPrevMessages.current = false;
                setLoadingPrevMessages(false);
            }
        }
    }

    const getRequest = () => {
        let req: MsnReq.IAllMessagesReq = {
            sorting: [{
                fieldName: 'Timestamp',
                desc: true
            }],
            filters: [],
            pagination: {
                offset: offset.current,
                limit: 50
            }
        }
        offset.current += 50;
        return req;
    }

    const getAllMessages = (req: MsnReq.IAllMessagesReq) => {
        MPAApi.Messenger.getAllMessages(conversationID, req)
            .then(res => {
                const response = res.data;
                if (response.status === 'success') {
                    let data = response.data;
                    // Set number of messages only the first time.
                    if (!numTotalMessage.current) {
                        numTotalMessage.current = data.totalRecords;
                        //setNumTotalMessage(data.totalRecords);
                    }
                    numReadMessage.current += data.currentRecords;
                    //setNumReadMessage(numReadMessage + data.currentRecords);
                    if (isLoading.current) {
                        setMessages(data.collection);
                    }
                    else if (data.collection && data.collection.length > 0) {
                        // Add previous message:
                        let prevMessages = data.collection.map(x => { x.skipAnimation = true; return x });
                        //setMessages([...prevMessages, ...messages]);
                        setMessages(messages => {
                            return [...prevMessages, ...messages];
                        });
                        isLoadingPrevMessages.current = false;
                        setLoadingPrevMessages(false);
                    } else {
                        setLoadingPrevMessages(false);
                    }
                    if (isLoading.current) {
                        // scrollTolastMessage("auto");
                        isLoading.current = false;
                    }
                }
            })
            .catch(e => {
                setMessages([]);
            });
    }

    const updateChatMessages = (message: WsRes.IWsMessage) => {
        if (message.conversationID === conversationID) {
            setMessages(prev => {
                let newMessage: MsnRes.IMessage = {
                    messengerMessageID: message.messageID,
                    memberGuid: message.memberGuid,
                    messengerConversationID: message.conversationID,
                    content: message.message,
                    timestamp: message.timestamp,
                    quotedMessage: message.quotedMessageID,
                    resources: message.attachments && message.attachments.length > 0 ? message.attachments.map(x => { return { name: x.original_name, resourceID: x.id, type: x.type, size: x.size } }) : [],
                    skipAnimation: false,
                }
                return [...prev, newMessage];
            });

            MPAApi.Messenger.updateLastInteraction(conversationID);

            // timeout = setTimeout(() => {
            //     scrollTolastMessage("smooth");
            // }, (100));
        }
    }

    const newReaction = (reaction: MsnReq.IReaction) => {
        setMessages(prev => {
            let newMessages = cloneDeep(prev);
            let messageFound = newMessages.find(x => x.messengerMessageID === reaction.messengerMessageID);
            if (messageFound) {

                if (!messageFound.reactions)
                    messageFound.reactions = [];

                messageFound.reactions.push(reaction);
            }
            return newMessages;
        });
    }

    const deleteReaction = (reactionID: number) => {
        setMessages(prev => {
            let _messages = cloneDeep(prev);
            _messages.forEach(x => {
                if (x.reactions) {
                    x.reactions = x.reactions.filter(y => y.messengerMessageReactionID !== reactionID)
                }
            });
            return _messages;
        });
    }

    const deleteChatMessage = (messageID: number) => {
        setMessages(prev => {
            let _messages = [...prev];
            return _messages.filter(x => x.messengerMessageID !== messageID);
        });
    }

    const handleUserInfoClick = (message: MsnRes.IMessage) => {
        let user = userList.find(y => y.memberGuid === message.memberGuid);
        let picture = images.find(y => y.resourceID === user?.userProfilePicture);
        if (picture) {
            setPictureUserInfo(picture.content);
        } else {
            setPictureUserInfo("");
        }
        setMessageClicked(message);
        setShowUserInfo(!showUserInfo);
    }

    const scrollTolastMessage = (behavior: ScrollBehavior) => {
        if (!showPanel) {
            if (dummy && dummy.current) {
                dummy.current.scrollIntoView({ behavior: behavior, block: 'nearest', inline: 'start' });
            }
        }
    }

    const style = useSpring({
        from: { opacity: showScrollToLastMessage ? 0 : 1 },
        to: { opacity: showScrollToLastMessage ? 1 : 0, cursor: showScrollToLastMessage ? "pointer" : "" },
        onStart: () => { showScrollToLastMessage && setLastMessageIndex(1) },
        onRest: () => { isMounted.current && setLastMessageIndex(showScrollToLastMessage ? 1 : -1) }
    })

    useWindowOnClick(
        (event: any) => {
            if (isMounted.current) {
                let elem = event.target;
                let showReactionTot = false;
                let showInfo = false;

                if (elem.id.indexOf('reaction-tot_') === -1) {
                    while (elem.parentElement) {
                        elem = elem.parentElement;
                        if (elem.id.indexOf('chat-user-info') > -1) {
                            showInfo = true;
                        }
                        if (elem.id.indexOf('reaction-tot_') > -1) {
                            showReactionTot = true;
                            break;
                        }
                    }
                } else {
                    showReactionTot = true;
                }
                if (!showReactionTot) {
                    setSelectedReaction({ messageID: 0, reactionID: 0 });
                }

                if (!showInfo) {
                    elem = event.target;
                    if (elem.id.indexOf('chat-user-info') === -1) {
                        while (elem.parentElement) {
                            elem = elem.parentElement;
                            if (elem.id.indexOf('chat-user-info') > -1) {
                                showInfo = true;
                                break;
                            }
                        }
                    } else {
                        showInfo = true;
                    }
                }
                if (!showInfo) {
                    setShowUserInfo(false);
                }
            }
        }
    )

    return (
        <div ref={wrapper} id="chat-messages" className="chat-messages-wrapper h-100 bg-color-chat-chat-messages d-flex flex-column-reverse">
            <div ref={dummy}></div>
            {
                !isLoading.current &&
                <animated.div style={{ ...style, zIndex: lastMessageIndex }} className="chat-messages-scroll-to-last" onClick={() => scrollTolastMessage("auto")}>
                    <FontAwesomeIcon icon={faAngleDoubleDown} className="font-size-13" />
                </animated.div>
            }
            {
                messages && messages.sort((a, b) => { return a.messengerMessageID < b.messengerMessageID ? 1 : -1 }).map((x, index) => {
                    let user = userList.find(y => y.memberGuid === x.memberGuid);
                    let quotedMessage = messages.find(y => y.messengerMessageID === x.quotedMessage);
                    let quotedUser;
                    if (quotedMessage) {
                        quotedUser = userList.find(y => y.memberGuid === quotedMessage?.memberGuid);
                    }
                    let picture = images.find(y => y.resourceID === user?.userProfilePicture);
                    return <ChatMessage key={x.messengerMessageID} userList={userList} message={x} user={user} images={images}
                        quotedMessage={quotedMessage} quotedUser={quotedUser} picture={picture ? picture.content : ""}
                        handleUserInfoClick={handleUserInfoClick} handleQuoteMessage={handleQuoteMessage} selectedReaction={selectedReaction} isSmallDevice={isSmallDevice} />
                })
            }
            {/* Used for scrolling always to the last message */}
            {
                (numTotalMessage.current || 0) > (numReadMessage.current || 0) &&
                <div ref={virtualScrollDummy} className="d-flex flex-direction-column ms-auto me-auto">
                    {/* {!loadingPrevMessages && showLoadPreviousMessagesButton && <span className="chat-messages-load-previous" onClick={loadPreviousMessages}>Load previous messages &nbsp; <FontAwesomeIcon icon={faSync} /> </span>} */}
                    <div className={`chat-messages-loading ms-auto me-auto ${isSmallDevice ? "mt-1 mb-1" : "mt-3 mb-3"} ${loadingPrevMessages ? "" : "invisible"}`}>
                        <div className="lds-ellipsis position-relative"><div /><div /><div /><div /></div>
                    </div>
                </div>
            }
            {
                messageClicked && messageClicked.memberGuid !== memberGuid &&
                <ChatUserInfo
                    user={userList.find(y => y.memberGuid === messageClicked.memberGuid)}
                    messageID={messageClicked.messengerMessageID}
                    showUserInfo={showUserInfo}
                    picture={pictureUserInfo}
                    setShowUserInfo={setShowUserInfo}></ChatUserInfo>
            }
        </div>
    )
}

export default ChatMessages;