import React, { useState, useEffect } from 'react';
import './Toggle.scss';

interface IProps {
    text?: string,
    isActive: boolean,
    className?: string,
    onClick: (active: boolean) => void
}

const Toggle = ({ text = '', isActive, className = '', onClick }: IProps) => {

    const [active, setActive] = useState<boolean>(false);

    useEffect(() => {
        setActive(isActive);
    }, [isActive])
    
    const clickHandler = () => {
        setActive(!active);
        onClick(!active);
    }

    return (
        <label className={`toggle ${className}`} htmlFor="" onClick={clickHandler} >
            <span className="toggle__text font-size-8 me-2">{text}</span>
            <div className="switch">
                <input type="checkbox" className="switch__checkbox" checked={active} onChange={() => null} />
                <span className="switch__slider"></span>
            </div>
        </label>
    );
    
}

export default Toggle;