import MessengerContainer from "./containers/MessengerContainer";
import './App.scss'
import { useEffect, useState } from "react";
import { AuthReq } from "./API/RequestModels/Auth";
import MPAApi from "./API/MPAApi";
import { StorageUtils } from "./config/function";
import { ContextAppSettings, IAppSettings } from "./config/context";
import { TMainLanguage } from './config/translations';
import cloneDeep from 'lodash/cloneDeep';
import Loader from "./components/Common/Loader";
import { initWebSocket } from "./config/websocket";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const initAppSettings: IAppSettings = {
  memberGuid: '',
  language: 'en' as TMainLanguage,
  selectedTheme: 'wio',
  messengerGuid: '',
  hasAccess: false,
  isBanned: false,
  reactions: [],
  setLoading: () => { }
};

let userHasAccessCalled = false;

function App() {
  const [appSettings, setAppSettings] = useState<IAppSettings>(initAppSettings);
  const [previewImage, setPreviewImage] = useState<string | null>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // Theme selector:
    // require('./theme/Default.scss');
    require('./theme/WiO.scss');

    const urlParams = new URLSearchParams(window.location.search);
    window.debug = urlParams.get('debug') === "true";
    const _messengerGuid = urlParams.get('messengerguid') || '0';

    // If debug = true, login to retrive token
    if (window.debug) {
      let userName = urlParams.get('username') || "gabriele.bessi@mixerpa.com";
      let password = urlParams.get('password') || "123456Ab";
      const data: AuthReq.ILogin = {
        userName: userName,
        password: password,
        rememberMe: false,
        authenticationMode: AuthReq.IAuthenticationMode.Local,
      }
      MPAApi.Auth.login(data)
        .then(res => {
          const response = res.data;
          if (response.status === 'success') {
            StorageUtils.SetItem("token", response.data.token);
            checkUserAccess(_messengerGuid);
          }
        }).catch(e => console.error("login - error", e));
    } else {
      const token = urlParams.get('token') || '';
      StorageUtils.SetItem("token", token);
      checkUserAccess(_messengerGuid);
    }

    window.previewImage = (value: string) => setPreviewImage(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkUserAccess = (_messengerGuid: string) => {
    MPAApi.Messenger.userHasAccess(_messengerGuid)
      .then(async res => {
        const response = res.data;
        if (response.status === 'success') {
          let _appSettings = cloneDeep(appSettings);
          _appSettings.memberGuid = response.data.memberGuid;
          _appSettings.hasAccess = response.data.hasAccess;
          _appSettings.isBanned = response.data.isBanned;
          _appSettings.messengerGuid = _messengerGuid;
          _appSettings.setLoading = (value: boolean) => setIsLoading(value);
          await MPAApi.Messenger.getAllReactions(_messengerGuid).then(res => {
            const response = res.data;
            if (response.status === 'success') {
              _appSettings.reactions = response.data;
            }
          }).catch(e => {
            console.error("getAllReactions - error", e);
          });
          setAppSettings(_appSettings);
          setIsLoading(false);
          if (window.debug) console.log("appSettings", appSettings);
          // Initialize websocket:
          if (!userHasAccessCalled && response.data.hasAccess) {
            initWebSocket(_messengerGuid, response.data.memberGuid);
            userHasAccessCalled = true;
          }
        }
      }).catch(e => {
        console.error("userHasAccess - error", e);
        setIsLoading(false);
      });
  }

  return (
    <div className="App h-100 w-100">
      <ContextAppSettings.Provider value={appSettings}>
        {
          appSettings.hasAccess ?
            !appSettings.isBanned ?
              <MessengerContainer checkUserAccess={checkUserAccess} />
              :
              <h1 className="user-has-not-access">User has been banned</h1>
            :
            <h1 className="user-has-not-access">User has not access</h1>
        }
        {
          isLoading &&
          <Loader />
        }
        {
          previewImage && previewImage !== "" &&
          <Lightbox
            mainSrc={previewImage}
            onCloseRequest={() => setPreviewImage(null)}
            enableZoom={false}
          />
        }
      </ContextAppSettings.Provider>
    </div>
  );
}

export default App;
