import './MenuOptions.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Common } from "../../models/Common";

interface IProps {
    id: string,
    options: Common.IMenuOption[]
}

const MenuOptions = ({ id, options }: IProps) => {
    return (
        <div id={`menu-options-wrapper_${id}`} className="menu-options-wrapper">
            {
                options.map((x, idx) => {
                    return (
                        <div key={idx} onClick={x.callback} className="menu-options-item d-flex align-items-center cursor-pointer">
                            <div className="me-2">
                                {
                                    x.icon &&
                                    <FontAwesomeIcon icon={x.icon} className={`font-size-10 ${x.className ? x.className : ""}`} />
                                }
                                {
                                    x.svg ? x.svg : null
                                }
                            </div>
                            <span>{x.label}</span>
                        </div>
                    )
                })

            }
        </div>
    )
}
export default MenuOptions;