import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import './InfoCircle.scss'

type IconProp = "faUsers" | "faUser" | "faCommentAlt" | "faAddressBook"

interface IProps {
    className: string,
    picture?: string,
    icon?: IconProp,
    userName?: string,
}

const InfoCircle = ({ className, picture, icon, userName }: IProps) => {

    return (
        <div className={"info-circle-wrapper rounded-circle mt-auto mb-auto overflow-hidden " + className}>
            {
                picture ?
                    <div className="info-circle__picture m-auto" style={{ backgroundImage: `url(${picture})` }} />
                    :
                    icon ?
                        <FontAwesomeIcon icon={Icons[icon]} className="tx-color-preview-icon m-auto" />
                        // <div className="info-circle__name">{userName}</div>
                        :
                        <div className="info-circle__name">{userName}</div>
            }
        </div>
    )
}

export default InfoCircle;