import { MsnRes } from '../../API/ResponseModels/Messenger';
import { ResourcesRes } from '../../API/ResponseModels/Resources';
import ChatMessageReactionUsers from '../../containers/Chat/ChatMessageReactionUsers';
import { ISelectedReaction } from '../../containers/Chat/ChatMessages';
import './ChatMessageReaction.scss'

interface IProps {
    selectedReaction: ISelectedReaction,
    reactionID: number,
    messageID: number,
    ownMessage: boolean,
    userList: MsnRes.IUser[],
    imageURL?: string,
    memberIds: string[],
    count: number,
    images: ResourcesRes.IBase64[],
}

const ChatMessageReaction = ({ selectedReaction, reactionID, messageID, ownMessage, userList, imageURL, memberIds, count, images }: IProps) => {
    if (window.logRender) console.log("ChatMessageReaction - render", reactionID);

    const handleUsersVisible = () => {
        if (window.selectReaction) {
            window.selectReaction(messageID, reactionID);
        }
    }

    return (
        <div id={`reaction-tot_${reactionID}"_"${messageID}`} className="chat-message-reaction-wrapper ms-1" onClick={() => handleUsersVisible()}>
            {
                selectedReaction.messageID === messageID && selectedReaction.reactionID === reactionID &&
                <div className={`chat-message-reaction-users ${ownMessage ? "own" : ""}`}>
                    {
                        memberIds && memberIds.length > 0 && memberIds.map((x, index) => {
                            let memberFound = userList.find(y => y.memberGuid === x);
                            if (memberFound) {
                                return <ChatMessageReactionUsers key={x} user={memberFound} picture={images.find(y => y.resourceID === memberFound?.userProfilePicture)} />
                            }
                            else {
                                return null;
                            }
                        })
                    }
                </div>
            }
            <div className="chat-message-reaction">
                <img alt="emote" className="chat-message-reaction__image ms-1 me-1" src={imageURL} />
                <span className="chat-message-reaction__count">{count}</span>
            </div>
        </div>
    );
}

export default ChatMessageReaction;