import { useContext, useEffect, useState } from "react";
import Contact from "../../components/MessengerPanel/Contact";
import SearchBox from "../../components/Common/SearchBox";
import "./ContactList.scss";
import { MsnRes } from "../../API/ResponseModels/Messenger";
import { ContextAppSettings } from "../../config/context";
import { ResourcesRes } from "../../API/ResponseModels/Resources";
import { cloneDeep } from "lodash";
import useWindowOnClick from "../../components/_hooks/useWindowOnClick";

interface IProps {
    userList: MsnRes.IUser[],
    images: ResourcesRes.IBase64[],
    setActiveConversation: Function,
    addConversation: Function,
    setSelectedTab: Function,
}

const ContactList = ({ userList, images, setActiveConversation, addConversation, setSelectedTab }: IProps) => {
    const [userListFiltered, setUserListFiltered] = useState<MsnRes.IUser[]>([]);
    const [myUserIsModerator, setMyUserIsModerator] = useState<boolean>(false);
    const [openedOptionsMenu, setOpenedOptionsMenu] = useState("");
    const { memberGuid } = useContext(ContextAppSettings);

    useEffect(() => {
        const myUser = userList.find(x => x.memberGuid === memberGuid);
        if (myUser) {
            setUserListFiltered(userList.filter(x => x.userPrivateMessage && x.memberGuid !== memberGuid && (myUser.isModerator || !x.isBanned)));
            setMyUserIsModerator(myUser.isModerator);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userList])

    const onChange = (e: any) => {
        let filter: string = e.target.value;
        if (filter) {
            filter = filter.toUpperCase();
        }
        let _userList = cloneDeep(userList.filter(x => x.userPrivateMessage && x.memberGuid !== memberGuid && (myUserIsModerator || !x.isBanned)));
        setUserListFiltered(_userList.filter(x => x.userFirstName.toUpperCase().includes(filter) || x.userLastName.toUpperCase().includes(filter)));
    }

    useWindowOnClick(
        (event: any) => {
            let elem = event.target;
            let show = false;
            if (elem.id.indexOf('menu-options') === -1) {
                while (elem.parentElement) {
                    elem = elem.parentElement;
                    if (elem.id.indexOf('menu-options') > -1) {
                        show = true;
                        break;
                    }
                }
            } else {
                show = true;
            }
            if (!show) {
                setOpenedOptionsMenu("");
            }
        }
    )

    return (
        <div className="messenger-panel-contact-list-wrapper bg-color-panel w-100 p-2">
            <SearchBox className="mb-3" onChange={onChange} />
            <hr className="m-0" />
            <div className="messenger-panel-contact-list overflow-auto">
                {
                    userListFiltered && userListFiltered.map((x, index) =>
                        <div key={x.memberGuid}>
                            <Contact user={x} myUserIsModerator={myUserIsModerator} image={images.find(y => y.resourceID === x.userProfilePicture)}
                                setActiveConversation={setActiveConversation}
                                addConversation={addConversation}
                                setSelectedTab={setSelectedTab}
                                openedOptionsMenu={openedOptionsMenu}
                                setOpenedOptionsMenu={setOpenedOptionsMenu} />
                            {
                                index < (userListFiltered.length - 1) &&
                                <hr className="m-0" />
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ContactList;