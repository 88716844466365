import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { StorageUtils } from '../config/function';

export default abstract class AxiosClient {

    protected readonly instance: AxiosInstance;

    public constructor(endPoint: string) {
        //Defining instance with endpoint:
        this.instance = axios.create({
            baseURL: endPoint
        });

        this._initializeRequestInterceptor();
        this._initializeResponseInterceptor();
    }

    private _initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(
            this._handleRequest
        );
    };

    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            this._handleResponse,
            this._handleError,
        );
    };

    private _handleRequest = (request: AxiosRequestConfig) => {
        const token = StorageUtils.GetItem("token");
        if (request && request.headers) {
            request.headers.Authorization = token ? token.value : '';
        }
        /*
        if (isIE()) {
            const url = request.url;
            if (url !== undefined) {
                request.url = url + (url.indexOf('?') > 0 ? '&' : '?') + 'id=' + new Date().getTime()
            }
        }
        */
        return request;
    }

    private _handleResponse = (response: AxiosResponse) => {
        return response;
    }

    protected _handleError = (error: string) => {
        console.error("AxiosClient: Response error:", error);
    }
}