import { useState } from "react";
import Conversation from "../../components/MessengerPanel/Conversation";
import SearchBox from "../../components/Common/SearchBox";
import { useEffect } from "react";
import './ConversationList.scss';
import { MsnRes } from '../../API/ResponseModels/Messenger';
import { ResourcesRes } from "../../API/ResponseModels/Resources";
import { useContext } from "react";
import { ContextAppSettings } from "../../config/context";
import { checkConversationHidden, getPreviewImage } from "../../config/function";
import { cloneDeep } from "lodash";
import useWindowOnClick from "../../components/_hooks/useWindowOnClick";
import Toggle from "../../components/Common/Toggle";
import { translations } from "../../config/translations";

interface IProps {
    conversationList: MsnRes.IConversation[],
    userList: MsnRes.IUser[],
    images: ResourcesRes.IBase64[],
    activeConversation?: MsnRes.IConversation,
    setActiveConversation: Function,
    animatedConversation: string,
    setAnimatedConversation: Function,
}

const ConversationList = ({ conversationList, userList, images, activeConversation, setActiveConversation, animatedConversation, setAnimatedConversation }: IProps) => {
    const { memberGuid, language } = useContext(ContextAppSettings);
    const [convFiltered, setConvFiltered] = useState<MsnRes.IConversation[]>([]);
    const [filter, setFilter] = useState("");
    const [hiddenConvVisible, setHiddenConvVisibility] = useState<boolean>(false);
    const [openedOptionsMenu, setOpenedOptionsMenu] = useState("");

    if (window.logRender) console.log("ConversationList - render");

    useEffect(() => {
        filterConversation(filter, hiddenConvVisible);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationList])

    const onChange = (e: any) => {
        let _filter = e.target.value;
        setFilter(_filter);
        filterConversation(_filter, hiddenConvVisible);
    }

    const filterConversation = (filter: string, showHidden?: boolean) => {
        if (filter) {
            filter = filter.toUpperCase();
        }
        let _conversationList = cloneDeep(conversationList);
        setConvFiltered(_conversationList.filter(x => applyFilter(x, filter, showHidden)).sort(sortConversation));
    }

    const applyFilter = (conversation: MsnRes.IConversation, filter: string, showHidden?: boolean) => {
        let visibile = false;
        // Check if conversation is hidden:
        if (!checkConversationHidden(conversation) || (showHidden && checkConversationHidden(conversation))) {
            // Match filtered text:
            if (conversation.conversationTitle.toUpperCase().includes(filter) ||
                (conversation.lastMessage && conversation.lastMessage.toUpperCase().includes(filter))) {
                visibile = true;
            }
        }
        return visibile;
    }

    const sortConversation = (a: MsnRes.IConversation, b: MsnRes.IConversation) => {
        let compare = 0;
        // Same type of conversation:
        if (a.messengerConversationTypeID === b.messengerConversationTypeID) {
            if (a.conversationPinned && b.conversationPinned) {
                if (a.conversationPinned > b.conversationPinned) {
                    compare = -1;
                }
                else if (a.conversationPinned < b.conversationPinned) {
                    compare = 1;
                }
                else {
                    compare = a.conversationTitle.toLowerCase().localeCompare(b.conversationTitle.toLowerCase());
                }
            }
            else if (b.conversationPinned) {
                compare = 1;
            }
            else if (a.conversationPinned) {
                compare = -1;
            }
            else {
                compare = a.conversationTitle.toLowerCase().localeCompare(b.conversationTitle.toLowerCase());
            }
        }
        else {
            // Different type of conversation (prio goes to main conversation, then groups at last one to one)
            if (a.messengerConversationTypeID === 1 || (a.messengerConversationTypeID === 3 && b.messengerConversationTypeID !== 1)) {
                compare = -1;
            }
            else {
                compare = 1;
            }
        }
        return compare;
    }

    const handleHiddenConv = () => {
        const newValue = !hiddenConvVisible;
        setHiddenConvVisibility(newValue);
        filterConversation(filter, newValue);
    }

    useWindowOnClick(
        (event: any) => {
            let elem = event.target;
            let show = false;
            if (elem.id.indexOf('menu-options') === -1) {
                while (elem.parentElement) {
                    elem = elem.parentElement;
                    if (elem.id.indexOf('menu-options') > -1) {
                        show = true;
                        break;
                    }
                }
            } else {
                show = true;
            }
            if (!show) {
                setOpenedOptionsMenu("");
            }
        }
    )

    return (
        <div className="messenger-panel-conversation-list-wrapper bg-color-panel w-100 p-2">
            <SearchBox className="mb-3" onChange={onChange} />
            <div className="d-inline-flex align-items-center mb-3 cursor-pointer" >
                <Toggle text={translations.panel.show_hidden_conv[language]} isActive={hiddenConvVisible} onClick={handleHiddenConv} />
            </div>
            <hr className="m-0" />
            <div className="messenger-panel-conversation-list">
                {
                    convFiltered && convFiltered.map((x, index) => {
                        let image: ResourcesRes.IBase64 | undefined = getPreviewImage(x, memberGuid, userList, images);
                        return (
                            <div key={x.messengerConversationID} className="position-relative" >
                                <Conversation
                                    conversation={x}
                                    title={x.conversationTitle}
                                    image={image}
                                    activeConversation={activeConversation}
                                    setActiveConversation={setActiveConversation}
                                    animatedConversation={animatedConversation}
                                    setAnimatedConversation={setAnimatedConversation}
                                    openedOptionsMenu={openedOptionsMenu}
                                    setOpenedOptionsMenu={setOpenedOptionsMenu}
                                />
                                {
                                    index < (convFiltered.length - 1) &&
                                    <hr className="m-0" />
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ConversationList;