import { useContext, useEffect, useState } from 'react';
import ContactList from './ContactList';
import ConversationList from './ConversationList';
import MessengerPanelHeader from '../../components/MessengerPanel/MessengerPanelHeader';
import { MsnRes } from '../../API/ResponseModels/Messenger';
import { ResourcesRes } from '../../API/ResponseModels/Resources';
import './MessengerPanel.scss'
import { Tabs } from '../../API/Enum';
import { ContextAppSettings } from '../../config/context';

interface IProps {
    userList: MsnRes.IUser[],
    conversationList: MsnRes.IConversation[],
    images: ResourcesRes.IBase64[],
    activeConversation?: MsnRes.IConversation,
    setActiveConversation: Function,
    addConversation: Function,
    animatedConversation: string,
    setAnimatedConversation: Function,
}

const MessengerPanel = ({ userList, conversationList, images, activeConversation, setActiveConversation, addConversation, animatedConversation, setAnimatedConversation }: IProps) => {
    const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Chat);
    const [showContactsTab, setShowContactsTab] = useState(true);
    const { memberGuid } = useContext(ContextAppSettings);

    useEffect(() => {
        if (userList.length > 0) {
            let index = userList.findIndex(x => x.memberGuid === memberGuid)
            if (index > -1) {
                setShowContactsTab(userList[index].userPrivateMessage)
            }
        }
    }, [userList])

    return (
        <div className="messenger-panel-wrapper h-100 w-100">
            <div className="messenger-panel h-100 w-100 d-flex flex-column">
                <MessengerPanelHeader selectedTab={selectedTab} setSelectedTab={setSelectedTab} showContactsTab={showContactsTab} />
                {
                    selectedTab === Tabs.Contacts ?
                        <ContactList userList={userList} images={images} setActiveConversation={setActiveConversation}
                            addConversation={addConversation} setSelectedTab={setSelectedTab} />
                        :
                        <ConversationList conversationList={conversationList} userList={userList} images={images}
                            activeConversation={activeConversation} setActiveConversation={setActiveConversation}
                            animatedConversation={animatedConversation} setAnimatedConversation={setAnimatedConversation} />
                }
            </div>
        </div>
    )
}

export default MessengerPanel;