import { IAttach } from "./Input/ChatInputText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faImage } from "@fortawesome/free-solid-svg-icons";
import { MsnRes } from "../../API/ResponseModels/Messenger";
import { APIUrl } from "../../config/config";
import { formatFileSize } from "../../config/attachment";

interface IProps {
    attach?: IAttach,
    resource?: MsnRes.IMessageResource,
    className?: string,
}

const ChatAttachment = ({ attach, resource, className }: IProps) => {
    let fileType = attach ? attach.fileType : resource ? resource.type : "";
    let fileName = attach ? attach.fileName : resource ? resource.name : "";
    let size = attach ? attach.fileSize : resource ? formatFileSize(resource.size) : "";
    let path = APIUrl() + "Resources/" + resource?.resourceID + "/Download";
    const isImage = fileType === 'IMAGE' || fileType.startsWith("image/") ? true : false;

    const handleDownload = () => {
        if (resource && resource.resourceID > 0) {
            if (isImage) {
                window.previewImage(path);
            }
            else {
                window.open(path);
            }
        }
    }

    return (
        <div className={`d-flex cursor-pointer me-2 ${className ? className : ""} 
            ${isImage && resource && resource.resourceID > 0 ? "flex-column" : "flex-row align-items-center"}`} onClick={handleDownload}>
            <div>
                {
                    isImage && resource && resource.resourceID > 0 ?
                        <img alt={resource.name} className="img-fluid" src={path} />
                        :
                        <FontAwesomeIcon icon={isImage ? faImage : faFile} className="font-size-12" />
                }
            </div>
            <div className="d-flex flex-column ms-2">
                <span className="ms-1 font-size-8">{fileName}</span>
                <span className="ms-1 font-size-9">{size}</span>
            </div>
        </div>
    );
}
export default ChatAttachment;