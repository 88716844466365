import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useContext } from "react";
import { MsnRes } from "../../API/ResponseModels/Messenger";
import { ContextAppSettings } from "../../config/context";
import { theme } from "../../config/theme";
import './QuotedMessage.scss';
import parse from 'html-react-parser';

interface IProps {
    user?: MsnRes.IUser,
    message: MsnRes.IMessage,
    removeQuotedMessage?: Function,
    hideCloseButton?: boolean,
}

const QuotedMessage = ({ user, message, removeQuotedMessage = () => { }, hideCloseButton = false }: IProps) => {
    const { selectedTheme } = useContext(ContextAppSettings);
    return (
        <div className="quoted-message bg-color-chat-quoted-message tx-color-chat-quoted-message d-flex flex-column">
            <div className="d-flex flex-row font-size-7">
                {moment(message.timestamp).format(theme.date_format[selectedTheme])}
                {
                    !hideCloseButton &&
                    <div className="ms-auto">
                        <FontAwesomeIcon icon={faTimes} className="cursor-pointer font-size-12" onClick={() => removeQuotedMessage()} />
                    </div>
                }
            </div>

            <hr className="chat-message-separator w-100"></hr>

            <div className="d-flex flex-column">
                <h2 className="font-size-10">{user?.userLastName + ' ' + user?.userFirstName}</h2>
                <p className="font-size-8">{parse(message.content)}</p>
            </div>
        </div>
    )
}
export default QuotedMessage;