import { MsnRes } from '../../../API/ResponseModels/Messenger';
import './ChatInputBox.scss';
import InfoCircle from '../../Common/InfoCircle';
import { ResourcesRes } from '../../../API/ResponseModels/Resources';

interface IProps {
    user: MsnRes.IUser,
    image?: ResourcesRes.IBase64,
    handleSelectUserFromAt: Function
}

const ChatInputContactSuggested = ({ user, image, handleSelectUserFromAt }: IProps) => {

    return (
        <div key={user.memberGuid} className="mt-1 mb-1 d-flex flex-row cursor-pointer pt-2 pb-2" onClick={() => handleSelectUserFromAt(user)} >
            <div className="position-relative">
                <InfoCircle picture={image?.content} userName={`${user.userFirstName.charAt(0)}${user.userLastName.charAt(0)}`} className="bg-color-info-circle-conversation ms-1 flex-shrink-0" />
                <div className={`messenger-panel-contact_status position-absolute ${user.online ? "bg-color-panel-contact-online" : "bg-color-panel-contact-offline"}`} />
            </div>
            <div className="messenger-panel-conversation_text d-flex flex-column ms-2 w-100">
                <div className="d-flex flex-row font-size-9 font-weight-bold">
                    {user.userFirstName} {user.userLastName}
                </div>
            </div>
        </div>
    )
}

export default ChatInputContactSuggested;
