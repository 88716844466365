import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

export const faThumbtackSolidSlash: IconDefinition = {
    // Use a prefix like 'fac' that doesn't conflict with a prefix in the standard Font Awesome styles
    // (So avoid fab, fal, fas, far, fa)
    prefix: "fas",
    iconName: "500px", // Name
    icon: [
        448, // width
        448, // height
        [], // ligatures
        "", // unicode (if relevant)
        "m235.6 76.7 29.8 29.8-51 41.5 142.3 142.3 41.4-50.9 29.8 29.8c9.4 9.4 24.5 9.4 33.9 0l33.9-33.9c9.4-9.4 9.4-24.5 0-33.9L303.4 8.9c-9.4-9.4-24.5-9.4-33.9 0l-33.9 33.9c-9.4 9.3-9.4 24.5 0 33.9zm-131.3 93.7C77 172 51.6 181.5 31.9 201.1c-9.4 9.4-9.4 24.5 0 33.9l96.2 96.2-73.5 73.5c-.9.9-1.5 1.9-1.9 3l-17 50.9c-2.1 6.2 3.9 12.2 10.1 10.1l50.9-17c1.2-.4 2.2-1.1 3-1.9l73.5-73.5 96.2 96.2c9.4 9.4 24.5 9.4 33.9 0 19.8-19.7 29.3-45.1 30.8-72.3L104.3 170.4zM30.49014.02948l473.96913 473.96913-30.47601 30.47601L.01413 30.50549z" // svg path data
    ]
}