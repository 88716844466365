import { memo } from "react";
import { MessengerConversationTypeID } from "../../API/Enum";
import { MsnRes } from "../../API/ResponseModels/Messenger";
import InfoCircle from "../Common/InfoCircle";
import './ChatHeader.scss'

interface IProps {
    conversation?: MsnRes.IConversation,
    picture?: string,
    title?: string,
    description?: string
    conversationTypeID?: MessengerConversationTypeID
}

const ChatHeader = ({ conversation, picture, title, description, conversationTypeID }: IProps) => {
    if (window.logRender) console.log("ChatHeader - render");

    return (
        <div className="chat-header-wrapper bg-color-chat-header">
            <div className="chat-header d-flex ">
                <InfoCircle className="bg-color-info-circle-message flex-shrink-0 ms-1" picture={picture} icon={conversationTypeID === MessengerConversationTypeID.OneToOne ? "faUser" : "faUsers"} />
                <div className="chat-header-info w-100 ms-2 mt-auto mb-auto d-flex flex-column">
                    <span className="chat-header-info__title font-size-10 font-weight-bold">{title}</span>
                    <div className="d-flex flex-row">
                        {
                            conversationTypeID === MessengerConversationTypeID.OneToOne &&
                            <div className={`messenger-panel-contact_status mt-auto mb-auto me-1 ${description === "Online" ? "bg-color-panel-contact-online" : "bg-color-panel-contact-offline"}`} />
                        }
                        <span className="chat-header-info__description tx-color-last-message font-size-9 mt-auto mb-auto">{description}</span>
                    </div>
                </div>
                {/*
                    conversation &&
                    (conversation.messengerConversationTypeID === MessengerConversationTypeID.OneToOne || conversation.messengerConversationTypeID === MessengerConversationTypeID.Group) &&
                    <div className="flex-shrink-0 d-flex me-3 align-items-center cursor-pointer">
                        <FontAwesomeIcon id="menu-options_button" icon={faEllipsisV} className="font-size-10" onClick={handleVisibilityMenu} />
                    </div>
                    */
                }
                {
                    /*
                    openedOptionsMenu === conversation?.messengerConversationID &&
                    <MenuOptions messengerConversationID={conversation ? conversation.messengerConversationID : ''} options={menuOptions} />
                    */
                }
            </div>
        </div>
    )
}

export default memo(ChatHeader, (a, b) => {
    if (a.title !== b.title ||
        a.description !== b.description ||
        a.conversationTypeID !== b.conversationTypeID ||
        a.picture !== b.picture) {
        return false;
    }
    return true;
});