import { WSUrl } from "./config";
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { StorageUtils } from "./function";
import { MessengerAction, MessengerCommand } from "../API/Enum";
import { WsRes } from "../API/ResponseModels/WebSocket";

export const initWebSocket = (messengerGuid: string, memberGuid: string) => {
    window.websocket = new HubConnectionBuilder().withUrl(WSUrl() + "?MessengerGuid=" + messengerGuid, {
        accessTokenFactory: () => StorageUtils.GetItem("token").value.replace("Bearer ", "")
    }).configureLogging(LogLevel.Debug).withAutomaticReconnect().build();

    window.websocket.start().then(() => {
    }).catch((e) => {
        console.error("websocket.start:", e);
    });

    window.websocket.on("ReceiveMessage", function (command: WsRes.IWsCommand) {
        if (window.debug) console.log("websocket - command", command);
        switch (command.action) {
            case MessengerAction.Message:
                if (window.updateConversationList) {
                    window.updateConversationList(command.message)
                }
                if (window.updateChatMessages) {
                    window.updateChatMessages(command.message)
                }
                break;
            case MessengerAction.Command:
                let operation = command.message as WsRes.IWsOperation;
                switch (operation.command) {
                    case MessengerCommand.Conversation:
                        if (window.addConversation) {
                            window.addConversation(operation.value)
                        }
                        break;
                    case MessengerCommand.UpdateConversation:
                        if (window.updateConversation) {
                            window.updateConversation(operation.value.conversation)
                        }
                        break;
                    case MessengerCommand.DeleteMessage:
                        if (window.updateConversationListOnDeleteMessage) {
                            window.updateConversationListOnDeleteMessage(operation.value)
                        }
                        if (window.deleteChatMessage) {
                            window.deleteChatMessage(operation.value)
                        }
                        break;
                    case MessengerCommand.UpdateUserStatus:
                        if (window.updateUserStatus) {
                            window.updateUserStatus(operation.value as WsRes.IWsUser)
                        }
                        break;
                    case MessengerCommand.NewReaction:
                        if (window.newReaction) {
                            window.newReaction(operation.value.reaction)
                        }
                        break;
                    case MessengerCommand.DeleteReaction:
                        if (window.deleteReaction) {
                            window.deleteReaction(operation.value)
                        }
                        break;
                    case MessengerCommand.BanUser:
                        if (window.banUser) {
                            window.banUser(operation.value)
                        }
                        break;
                    case MessengerCommand.UnbanUser:
                        if (window.unbanUser) {
                            window.unbanUser(operation.value)
                        }
                        break;
                }
                break;
            default:
                break;
        }
    });
}