import { useEffect } from "react";

export default function useWindowOnClick(callBack: Function) {

    useEffect(() => {
        document.addEventListener('click', (e: any) => callBack(e));
        return () => {
            document.removeEventListener('click', (e: any) => callBack(e));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}