import { memo, useContext } from "react";
import { MessengerConversationTypeID, Tabs } from "../../API/Enum";
import { MsnRes } from "../../API/ResponseModels/Messenger";
import { ResourcesRes } from "../../API/ResponseModels/Resources";
import { ContextAppSettings } from "../../config/context";
import InfoCircle from "../Common/InfoCircle";
import _uniqueId from 'lodash/uniqueId';
import "./Contact.scss";
import MenuOptions from "../Common/MenuOptions";
import { Common } from "../../models/Common";
import { translations } from "../../config/translations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserSlash, faUserCheck, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import MPAApi from "../../API/MPAApi";

interface IProps {
    user: MsnRes.IUser,
    myUserIsModerator: boolean,
    image?: ResourcesRes.IBase64,
    setActiveConversation: Function,
    addConversation: Function,
    setSelectedTab: Function,
    openedOptionsMenu: string,
    setOpenedOptionsMenu: Function,
}

const Contact = ({ user, myUserIsModerator, image, addConversation, setSelectedTab, openedOptionsMenu, setOpenedOptionsMenu }: IProps) => {
    const { memberGuid, messengerGuid, language } = useContext(ContextAppSettings);

    if (window.logRender) console.log("Contact - render", user.memberGuid);

    const addNewConversation = () => {
        let conversation: MsnRes.IConversation = {
            messengerConversationID: _uniqueId("conv_"),
            messengerGuid: messengerGuid,
            messengerConversationTypeID: MessengerConversationTypeID.OneToOne,
            firstMemberGuid: memberGuid,
            secondMemberGuid: user.memberGuid,
            conversationTitle: user.userFirstName + " " + user.userLastName,
            conversationSubtitle: "",
            previewImageResourceID: user.userProfilePicture,
            isBlocked: false,
            lastMessageID: 0,
            lastMessage: "",
            pinned: false,
            participants: [],
            newMessagesNumber: 0,
        }
        addConversation(conversation);
        setSelectedTab(Tabs.Chat);
    }

    const checkUserBanned = () => {
        return user.isBanned;
    }

    const handleVisibilityMenu = (e: any) => {
        e.stopPropagation();
        if (openedOptionsMenu === user.memberGuid) {
            setOpenedOptionsMenu("");
        } else {
            setOpenedOptionsMenu(user.memberGuid);
        }
    }

    const handleBan = () => {
        if (checkUserBanned()) {
            MPAApi.Messenger.unbanUser(messengerGuid, user.memberGuid).then(res => {
                const response = res.data;
                if (response.status === "success" && window.debug) {
                    console.log('unbanUser - done');
                }
            });
        }
        else {
            MPAApi.Messenger.banUser(messengerGuid, user.memberGuid).then(res => {
                const response = res.data;
                if (response.status === "success" && window.debug) {
                    console.log('banUser - done');
                }
            });
        }
    }

    let menuOptions: Common.IMenuOption[] = [
        {
            label: checkUserBanned() ? translations.menu.unban[language] : translations.menu.ban[language],
            icon: checkUserBanned() ? faUserCheck : faUserSlash,
            callback: (e: any) => {
                e.stopPropagation();
                handleBan();
                setOpenedOptionsMenu("");
            },
        }];

    return (
        <div className="position-relative">
            <div className="messenger-panel-conversation d-flex flex-row cursor-pointer pt-2 pb-2" onClick={addNewConversation} >
                <div className="position-relative">
                    <InfoCircle picture={image?.content} userName={`${user.userFirstName.charAt(0)}${user.userLastName.charAt(0)}`} className="bg-color-info-circle-conversation ms-1 flex-shrink-0" />
                    <div className={`messenger-panel-contact_status position-absolute ${user.online ? "bg-color-panel-contact-online" : "bg-color-panel-contact-offline"}`} />
                </div>
                <div className="messenger-panel-conversation_text d-flex flex-column ms-2 w-100">
                    <div className="d-flex flex-row font-size-9 font-weight-bold">
                        {user.userFirstName} {user.userLastName}
                    </div>
                    {
                        user.isBanned ?
                            <i className="d-flex flex-row font-size-8 ms-1">
                                {translations.contact.banned[language]}
                            </i> :
                            myUserIsModerator && user.bannedCounts > 0 ?
                                <i className="d-flex flex-row font-size-8 ms-1">
                                    {translations.contact.blocked_times[language].replace("#", user.bannedCounts.toString())}
                                </i> :
                                null
                    }
                </div>
                <div className="d-flex" onClick={handleVisibilityMenu}>
                    {myUserIsModerator &&
                        <div className="flex-shrink-0 d-flex me-2 align-items-center">
                            <FontAwesomeIcon id="menu-options_button" icon={faEllipsisH} className="font-size-10" />
                        </div>
                    }
                </div>
            </div>
            {
                openedOptionsMenu === user.memberGuid &&
                <MenuOptions id={user.memberGuid} options={menuOptions} />
            }
        </div>
    )
}

export default memo(Contact, (a, b) => {
    let prevImage = a.image ? a.image.content : "";
    let newImage = b.image ? b.image.content : "";
    if (prevImage.length !== newImage.length) {
        return false;
    }
    if (a.user.online !== b.user.online ||
        a.user.isBanned !== b.user.isBanned ||
        a.user.userFirstName !== b.user.userFirstName ||
        a.user.userLastName !== b.user.userLastName) {
        return false;
    }
    if (a.openedOptionsMenu !== b.openedOptionsMenu) {
        return false;
    }
    return true;
});