import moment from "moment";
import { memo, useContext } from "react";
import { MessengerConversationTypeID } from "../../API/Enum";
import { MsnRes } from "../../API/ResponseModels/Messenger";
import { ResourcesRes } from "../../API/ResponseModels/Resources";
import InfoCircle from "../Common/InfoCircle";
import { animated, useSpring } from "react-spring"
import { theme } from "../../config/theme";
import "./Conversation.scss";
import { ContextAppSettings } from "../../config/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";
import MenuOptions from "../Common/MenuOptions";
import { Common } from "../../models/Common";
import { faThumbtack, faEllipsisH, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { checkConversationHidden, handleBlock, handleHide, handlePin } from "../../config/function";
import { faThumbtackSolidSlash } from "../../svg/CustomIcon";
import { translations } from "../../config/translations";
import parse from 'html-react-parser';

interface IProps {
    conversation: MsnRes.IConversation,
    title: string,
    image?: ResourcesRes.IBase64,
    activeConversation?: MsnRes.IConversation,
    setActiveConversation: Function,
    animatedConversation: string,
    setAnimatedConversation: Function,
    openedOptionsMenu: string,
    setOpenedOptionsMenu: Function,
}

const Conversation = ({ conversation, title, image, activeConversation, setActiveConversation, animatedConversation, setAnimatedConversation, openedOptionsMenu, setOpenedOptionsMenu }: IProps) => {
    const { selectedTheme, memberGuid, language } = useContext(ContextAppSettings);

    if (window.logRender) console.log("Conversation - render", conversation.messengerConversationID);

    const styles = useSpring({
        from: {
            backgroundColor: animatedConversation === conversation.messengerConversationID ? theme.highlight_message[selectedTheme] : 'transparent'
        },
        to: {
            backgroundColor: 'transparent'
        },
        config: {
            duration: 500,
        },
        reset: true,
        onRest: () => { setAnimatedConversation("") }
    });

    let menuOptions: Common.IMenuOption[] = [
        {
            label: checkConversationHidden(conversation) ? translations.menu.unhide[language] : translations.menu.hide[language],
            icon: checkConversationHidden(conversation) ? faEye : faEyeSlash,
            callback: () => {
                handleHide(conversation);
                setOpenedOptionsMenu("");
            },
        }];

    if (!checkConversationHidden(conversation)) {
        menuOptions = [{
            label: conversation.pinned ? translations.menu.unpin[language] : translations.menu.pin[language],
            icon: !conversation.pinned ? faThumbtack : faThumbtackSolidSlash,
            className: !conversation.pinned ? "fa-rotate-45" : "",
            callback: () => {
                handlePin(conversation);
                setOpenedOptionsMenu("");
            },
        }, ...menuOptions];
    }

    if (conversation.messengerConversationTypeID === MessengerConversationTypeID.OneToOne &&
        (!conversation.isBlocked || conversation.blockedByMemberGuid === memberGuid)) {
        menuOptions.push({
            label: !conversation.isBlocked ? translations.menu.block[language] : translations.menu.unblock[language],
            icon: !conversation.isBlocked ? faLock : faLockOpen,
            callback: () => {
                handleBlock(conversation);
                setOpenedOptionsMenu("");
            },
        });
    }

    const handleVisibilityMenu = (e: any) => {
        e.stopPropagation();
        if (openedOptionsMenu === conversation.messengerConversationID) {
            setOpenedOptionsMenu("");
        } else {
            setOpenedOptionsMenu(conversation.messengerConversationID);
        }
    }

    return (
        <animated.div style={styles}>
            <div className={"messenger-panel-conversation d-flex flex-row cursor-pointer pt-2 pb-2 " + (activeConversation?.messengerConversationID === conversation.messengerConversationID ? "bg-color-panel-conversation-selected" : "none")}
                onClick={() => setActiveConversation(conversation)} style={{ opacity: checkConversationHidden(conversation) || conversation.isBlocked ? 0.5 : 1 }}>
                <InfoCircle picture={image?.content} icon={conversation.messengerConversationTypeID === MessengerConversationTypeID.OneToOne ? "faUser" : "faUsers"} className={(activeConversation?.messengerConversationID === conversation.messengerConversationID ? "bg-color-info-circle-conversation-selected" : "bg-color-info-circle-conversation") + " ms-1 flex-shrink-0"} />
                <div className="messenger-panel-conversation_text d-flex flex-column ms-2 w-100">
                    <div className="d-flex flex-row font-size-9">
                        <span className="messenger-panel-conversation_title font-weight-bold">{title}</span>
                        {
                            conversation.lastMessageDate &&
                            <span className="ms-auto mt-auto mb-auto me-1 font-size-7">{moment(conversation.lastMessageDate).format(theme.date_format_conversation[selectedTheme])}</span>
                        }
                    </div>
                    <div className="d-flex flex-row">
                        <div className="messenger-panel-conversation_last-message font-size-9 tx-color-last-message">
                            {conversation.lastMessage ? (conversation.lastMessage.indexOf('<div style="position: relative;') > -1 ? "" : parse(conversation.lastMessage)) : ""}
                        </div>
                        <div className="d-flex ms-auto align-items-center">
                            <div className="d-flex" onClick={handleVisibilityMenu}>
                                {(conversation.messengerConversationTypeID === MessengerConversationTypeID.OneToOne ||
                                    conversation.messengerConversationTypeID === MessengerConversationTypeID.Group) &&
                                    <div className="flex-shrink-0 d-flex me-2">
                                        <FontAwesomeIcon id="menu-options_button" icon={faEllipsisH} className="font-size-10" />
                                    </div>
                                }
                                {
                                    conversation.pinned &&
                                    <div className="flex-shrink-0 d-flex me-2 fa-rotate-45">
                                        <FontAwesomeIcon icon={faThumbtack} className="font-size-10" />
                                    </div>
                                }
                            </div>
                            {
                                conversation.newMessagesNumber > 0 &&
                                <div className="messenger-panel-conversation-message_number flex-shrink-0 d-flex rounded-circle mt-auto mb-auto me-1">
                                    <div className="m-auto font-size-7">
                                        {conversation.newMessagesNumber > 99 ? "99+" : conversation.newMessagesNumber}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                openedOptionsMenu === conversation.messengerConversationID &&
                <MenuOptions id={conversation.messengerConversationID} options={menuOptions} />
            }
        </animated.div >
    )
}

export default memo(Conversation, (a, b) => {
    let prevImage = a.image ? a.image.content : "";
    let newImage = b.image ? b.image.content : "";
    if (prevImage.length !== newImage.length) {
        return false;
    }
    if (a.title !== b.title) {
        return false;
    }
    if (a.animatedConversation !== b.animatedConversation &&
        b.animatedConversation === b.conversation.messengerConversationID) {
        return false;
    }
    let prevConversationID = a.activeConversation ? a.activeConversation.messengerConversationID : "";
    let newConversationID = b.activeConversation ? b.activeConversation.messengerConversationID : "";
    if (prevConversationID !== newConversationID &&
        (prevConversationID === b.conversation.messengerConversationID || newConversationID === b.conversation.messengerConversationID)) {
        return false;
    }
    if (a.conversation.lastMessage !== b.conversation.lastMessage ||
        a.conversation.lastMessageID !== b.conversation.lastMessageID ||
        a.conversation.newMessagesNumber !== b.conversation.newMessagesNumber ||
        a.conversation.pinned !== b.conversation.pinned ||
        a.conversation.conversationHidden !== b.conversation.conversationHidden ||
        a.conversation.isBlocked !== b.conversation.isBlocked ||
        a.conversation.blockedByMemberGuid !== b.conversation.blockedByMemberGuid) {
        return false;
    }
    let newCurrentConvID = b.conversation.messengerConversationID;
    if (a.openedOptionsMenu !== b.openedOptionsMenu &&
        (newCurrentConvID === b.openedOptionsMenu || newCurrentConvID === a.openedOptionsMenu)) {
        return false;
    }
    return true;
});

// export default Conversation;