import { useEffect, useState } from "react"

let timeoutId: any = null;
export default function useScroll(container: any | null) {
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setScroll(container.scrollTop || 0), 300);
        }

        if (container) {
            container.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        }
    }, [container])

    return scroll;
}