import React from 'react';
import { MsnRes } from '../API/ResponseModels/Messenger';
import { Theme } from './theme';
import { TMainLanguage } from './translations';

export interface IAppSettings {
    language: TMainLanguage,
    selectedTheme: Theme,
    memberGuid: string,
    messengerGuid: string,
    hasAccess: boolean,
    isBanned: boolean,
    reactions: MsnRes.IReactionType[],
    setLoading: Function,
}

const initAppSettings: IAppSettings = {
    language: 'en',
    selectedTheme: 'default',
    memberGuid: '',
    messengerGuid: '',
    hasAccess: false,
    isBanned: false,
    reactions: [],
    setLoading: () => { }
}

export const ContextAppSettings = React.createContext(initAppSettings);