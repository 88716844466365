
import { useContext, useEffect, useState } from 'react';
import MPAApi from '../../API/MPAApi';
import { MsnReq } from '../../API/RequestModels/Messenger';
import { MsnRes } from '../../API/ResponseModels/Messenger';
import { ContextAppSettings } from '../../config/context';
import { theme } from '../../config/theme';
import './ChatMessageReactionSuggested.scss'

interface IProps {
    reaction: MsnRes.IReactionType,
    message: MsnRes.IMessage,
}

let disableReaction = false;
const ChatMessageReactionSuggested = ({ reaction, message }: IProps) => {

    const { memberGuid, selectedTheme } = useContext(ContextAppSettings);
    const [reactionAddedByMember, setReactionAddedByMember] = useState<MsnRes.IReaction>();

    useEffect(() => {
        setReactionAddedByMember(message.reactions?.find(x => x.memberGuid === memberGuid && x.messengerReactionTypeID === reaction.messengerReactionTypeID));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message.reactions]);

    const handleClickReaction = () => {
        if (!disableReaction) {
            disableReaction = true;
            if (reactionAddedByMember) {
                handleRemoveReaction(reactionAddedByMember);
            }
            else {
                handleAddReaction();
            }
        }
    }

    const handleAddReaction = () => {
        let newReaction: MsnReq.IReaction = {
            messengerMessageReactionID: 0,
            memberGuid: memberGuid,
            messengerMessageID: message.messengerMessageID,
            messengerReactionTypeID: reaction.messengerReactionTypeID,
        }
        MPAApi.Messenger.addReaction(message.messengerConversationID, newReaction)
            .then(res => {
                const response = res.data;
                if (response.status === "success" && window.debug) {
                    console.log('addReaction - done');
                }
                reEnableReaction();
            })
            .catch(e => {
                console.error("addReaction - error", e);
                reEnableReaction();
            })
    }

    const handleRemoveReaction = (reactionAddedByMember: MsnRes.IReaction) => {
        MPAApi.Messenger.deleteReaction(message.messengerConversationID, reactionAddedByMember.messengerMessageReactionID)
            .then(res => {
                const response = res.data;
                if (response.status === "success" && window.debug) {
                    console.log('removeReaction - done')
                }
                reEnableReaction();
            })
            .catch(e => {
                console.error("removeReaction - error", e);
                reEnableReaction();
            })
    }

    const reEnableReaction = () => {
        setTimeout(() => {
            disableReaction = false;
        }, 300);
    }

    return (
        <div className="chat-message-reaction-suggested-wrapper" onClick={handleClickReaction}>
            <div className="chat-message-reaction-suggested">
                <img alt="emote" className="chat-message-reaction-suggested__image m-1" src={reaction.imageURL} />
                <span className="chat-message-reaction-suggested__circle" style={{ visibility: reactionAddedByMember ? "visible" : "hidden", backgroundColor: theme.reaction_border_color[selectedTheme] }} />
            </div>
        </div>
    );
}

export default ChatMessageReactionSuggested;