export type Theme = 'default' | 'wio';

type IThemeSelector = {
    [key in Theme]: string;
};

interface ITheme {
    highlight_message: IThemeSelector,
    input_emoji_border_color: IThemeSelector,
    reaction_border_color: IThemeSelector,
    date_format: IThemeSelector,
    date_format_conversation: IThemeSelector,
}
export const theme: ITheme = {
    highlight_message: {
        default: "#0081c9",
        wio: "#c7aabe",
    },
    input_emoji_border_color: {
        default: "#eaeaea",
        wio: "#c7aabe",
    },
    reaction_border_color: {
        default: "#bbb",
        wio: "#c7aabe",
    },
    date_format: {
        default: "yyyy-MM-DD HH:mm",
        wio: "yyyy-MM-DD HH:mm",
    },
    date_format_conversation: {
        default: "yyyy-MM-DD",
        wio: "yyyy-MM-DD",
    }
}