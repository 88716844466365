import { AxiosInstance } from "axios";
import { ResourcesReq } from "../RequestModels/Resources";
import { ResourcesRes } from "../ResponseModels/Resources";

class Resource {
    private _controllerName: string = "Resources";
    private _axios: AxiosInstance;

    constructor(axiosInstance: any) {
        this._axios = axiosInstance;
    }

    getResourceBase64 = (resourceID: number) => {
        return this._axios.get<any>(`${this._controllerName}/${resourceID}/base64`);
    }

    uploadResource = (resource: ResourcesReq.IEditResource) => {
        let data = new FormData();
        data.append('AttachedFile', resource.attachedFile);
        data.append('ResourceTypeID', resource.resourceTypeID);
        data.append('ResourceCategoryID', resource.resourceCategoryID.toString());
        data.append('Public', `${resource.public}`);
        data.append('Published', `${resource.published}`); 
        data.append('IsExternal', `${resource.isExternal}`);
        data.append('Priority', resource.priority.toString());
        return this._axios.post<FormData, ResourcesRes.GetResourceID>(`${this._controllerName}`, data);
    }
}

export default Resource;