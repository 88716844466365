import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { formatFileSize } from "../../../config/attachment";
import Button from "../../Common/Button";
import ChatAttachment from "../ChatAttachment";
import { IAttach } from "../Input/ChatInputText";
import validator from 'validator';
import { ResourcesReq } from "../../../API/RequestModels/Resources";
import { MsnRes } from "../../../API/ResponseModels/Messenger";
import { MsnReq } from "../../../API/RequestModels/Messenger";
import MPAApi from "../../../API/MPAApi";
import { ContextAppSettings } from "../../../config/context";
import { APIUrl } from "../../../config/config";
import "./UploadImageLink.scss";

interface IProps {
    conversation?: MsnRes.IConversation,
    show: boolean,
    setShow: Function,
}

let onConfirm = false;
const UploadImageLink = ({ conversation, show, setShow }: IProps) => {
    const { memberGuid } = useContext(ContextAppSettings);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [attachments, setAttachments] = useState<IAttach[]>([]);
    const [btnUploadDisabled, setBtnUploadDisabled] = useState(false);
    const fileUpload = useRef<HTMLInputElement>(null);
    const urlRef = useRef<HTMLInputElement>(null);
    const textRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (show) {
            setAttachments([]);
            setErrorMessages([]);
            setBtnUploadDisabled(false);
        }
    }, [show])

    const handleUpload = () => {
        if (fileUpload && fileUpload.current && (!textRef.current || textRef.current.value === "")) {
            fileUpload.current.click();
        }
    }

    const handleAttachChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let attachments: IAttach[] = [];
        let files = e.target.files;
        if (files && files.length > 0) {
            Array.from(files).forEach(x => {
                attachments.push({
                    file: x,
                    fileSize: formatFileSize(x.size),
                    fileName: x.name,
                    fileType: x.type,
                })
            });
        }
        setAttachments(attachments);
        if (textRef.current) {
            textRef.current.value = "";
        }
    }

    const removeAttachments = () => {
        setAttachments([]);
    }

    const handleConfirm = async () => {
        if (urlRef.current && !onConfirm) {
            onConfirm = true;
            let errors: string[] = []; 
            // Check if an image has been uploaded or if a text is specified:
            const text = textRef.current ? textRef.current.value : "";
            if ((!attachments || attachments.length === 0) && !text) {
                errors.push("Please upload an image attachment or type the text of the link");
            }
            // Check url: 
            if (!validator.isURL(urlRef.current.value, { require_protocol: true, require_valid_protocol: true })) {
                errors.push("The link provided is not a valid url!");
            }
            if (errors.length > 0) {
                setErrorMessages(errors);
                onConfirm = false;
            } else {
                console.log("handleConfirm");
                await sendMessage(urlRef.current.value, text);
            }
        }
    }

    const sendMessage = async (link: string, text: string) => {
        let sendMessage = true;
        let resourceID = 0;
        let imgPath = "";
        if (attachments && attachments.length > 0) {
            await Promise.all(attachments.map(x => {
                let resource: ResourcesReq.IEditResource = {
                    attachedFile: x.file,
                    resourceTypeID: '',
                    resourceCategoryID: conversation && conversation.attachmentsFolder ? conversation.attachmentsFolder : 0,
                    title: x.fileName,
                    public: true,
                    published: true,
                    isExternal: false,
                    priority: 0,
                }
                console.log("resource", resource)
                return MPAApi.Resource.uploadResource(resource)
            })).then(res => {
                if (res.length > 0) {
                    res.forEach(x => {
                        const response = x.data;
                        if (response.status === "success") {
                            resourceID = response.data;
                        } else {
                            sendMessage = false;
                        }
                    })
                }
            }).catch(e => { sendMessage = false; console.error("uploadResource - error", e); });

            // Prepare html message:
            imgPath = APIUrl() + "Resources/" + resourceID + "/Download";
        }

        // const htmlMessage = `<a href="${link}" target="_blank"><img class="img-fluid" src="${imgPath}"/></a>`;
        let htmlMessage = "";
        if (imgPath) {
            htmlMessage = `
        <div style="position: relative; min-height: 4rem; display: flex; width: 100%;">
            <a href="${link}" target="_blank" title="${link}">
                <div class="link-icon-wrapper" style="position: absolute; display: flex; border-radius: 50%; background: rgb(128 128 128);">
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="link" class="svg-inline--fa fa-link fa-w-16 fa-lg cursor-pointer" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" color="grey" style="color: #fff; margin: auto;">
                       <path fill="currentColor" d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z"></path>
                    </svg>
                </div>
            </a>
            <a href="${link}" style="margin: auto" target="_blank" title="${link}"><img class="img-fluid" src="${imgPath}"/></a>
        </div>`;
        } else {
            htmlMessage = `<a href="${link}" target="_blank" title="${link}">${text}</a>`
        }
        const req: MsnReq.IEditMessengerMessage = {
            memberGuid: memberGuid,
            content: htmlMessage,
            archived: false,
            anonymous: false,
            resourcesID: [],
            quotedMessage: 0,
            memberGuidsTagged: [],
        }
        if (sendMessage && conversation) {
            MPAApi.Messenger.sendMessage(conversation.messengerConversationID, req)
                .then(res => {
                    const response = res.data;
                    if (response.status === 'success') {
                        if (conversation && conversation.messengerGuid)
                            MPAApi.Messenger.saveLog(conversation.messengerGuid, { messengerConversationID: conversation.messengerConversationID, action: "SendMessage", agent: navigator.userAgent });
                    }
                    setShow(false);
                })
                .finally(() => {
                    onConfirm = false;
                })
                .catch(e => console.error("sendMessage - error", e))
        } else {
            onConfirm = false;
        }
    }

    return (
        <Modal show={show} size="xl" onHide={() => setShow(false)}>
            <ModalHeader>
                <ModalTitle className="d-flex m-auto">
                    Add an image with a link or a text with a link
                </ModalTitle>
                <FontAwesomeIcon className="ml-auto cursor-pointer" icon={faTimes} onClick={() => setShow(false)}></FontAwesomeIcon>
            </ModalHeader>
            <ModalBody>
                {/* <FontAwesomeIcon className={`mt-auto mb-auto ${isSmallDevice ? "ms-1" : "ms-4"} me-1 cursor-pointer`} icon={faPaperclip} size={isSmallDevice ? "1x" : "lg"} color="grey" onClick={handleUpload} /> */}
                <div className="row mt-3" >
                    {
                        attachments && attachments.length > 0 ?
                            <div className="col-6 d-flex flex-row" >
                                <div className="ms-2 p-1 bg-color-chat-attachments d-flex flex-row">
                                    {
                                        attachments.map((x, index) =>
                                            <ChatAttachment key={index} attach={x} />
                                        )
                                    }
                                </div>
                                <div className="ms-auto tx-color-chat-attachments-close">
                                    <FontAwesomeIcon icon={faTimes} className="rounded-button cursor-pointer font-size-12" onClick={removeAttachments} />
                                </div>
                            </div>
                            :
                            <div className="col-6 d-flex flex-row">
                                <input ref={fileUpload} id="uploadFile" accept=".png, .jpg, .jpeg, .JPEG" className="d-none" type="file" onChange={handleAttachChange} onClick={(e) => { e.currentTarget.value = "" }} />
                                <Button disabled={btnUploadDisabled} classes={"modal-button font-size-10"} icon="faUpload" clicked={() => handleUpload()}>Upload</Button>
                            </div>
                    }
                    <div className="col-6 d-flex">
                        <label>Text</label>
                        <input ref={textRef} className="modal-input ms-3" type="text" onChange={() => {
                            if (fileUpload.current) {
                                if (textRef.current && textRef.current.value !== "") {
                                    setBtnUploadDisabled(true);
                                }
                                else {
                                    setBtnUploadDisabled(false);
                                }
                            }
                        }} disabled={attachments && attachments.length > 0} />
                    </div>
                </div>

                <div className="mt-3 d-flex">
                    <label>Link</label>
                    <input ref={urlRef} className="modal-input ms-3" type="text" />
                </div>
                {
                    errorMessages.length > 0 &&
                    <div className="mt-3 error-message d-flex flex-column">
                        {
                            errorMessages.map(x =>
                                <span>{x}</span>
                            )
                        }
                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <Button withClass={["small"]} classes={"modal-button font-size-10"} clicked={() => handleConfirm()}>Confirm</Button>
            </ModalFooter>
        </Modal>
    )
}

export default UploadImageLink;