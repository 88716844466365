import { AxiosInstance } from "axios";
import { MsnReq } from "../RequestModels/Messenger";
import { MsnRes } from "../ResponseModels/Messenger";
import _baseApiResponse from "../ResponseModels/_baseApiResponse";
import { objToQueryParams } from "../../config/function";

class Messenger {
    private _controllerName: string = "Messenger";
    private _axios: AxiosInstance;

    constructor(axiosInstance: any) {
        this._axios = axiosInstance;
    }

    /* Messengers */

    /* Conversations */
    getConversations = (messengerGuid: string, query?: MsnReq.IQueryConversation) => {
        const queryString = objToQueryParams(query || {});
        return this._axios.get<MsnReq.IQueryConversation, MsnRes.ConversationsGet>(`${this._controllerName}/${messengerGuid}/Conversations${queryString}`);
    }
    getConversation = (conversationID: string, query?: MsnReq.IQueryConversation) => {
        const queryString = objToQueryParams(query || {});
        return this._axios.get<MsnReq.IQueryConversation, MsnRes.ConversationGet>(`${this._controllerName}/Conversation/${conversationID}${queryString}`);
    }
    addConversation = (conversation: MsnReq.IConversation) => {
        return this._axios.post<MsnReq.IConversation, MsnRes.AddConversationGet>(`${this._controllerName}/Conversation`, conversation);
    }
    blockConversation = (conversationID: string) => {
        return this._axios.post<null, _baseApiResponse>(`${this._controllerName}/Conversation/${conversationID}/Block`);
    }
    unBlockConversation = (conversationID: string) => {
        return this._axios.post<null, _baseApiResponse>(`${this._controllerName}/Conversation/${conversationID}/UnBlock`);
    }

    /* Messages */
    getAllMessages = (messengerConversationID: string, req: MsnReq.IAllMessagesReq) => {
        return this._axios.post<MsnReq.IAllMessagesReq, MsnRes.MessagesGet>(`${this._controllerName}/Conversation/${messengerConversationID}/AllMessages`, req);
    }

    sendMessage = (messengerConversationID: string, req: MsnReq.IEditMessengerMessage) => {
        return this._axios.post<MsnReq.IEditMessengerMessage, _baseApiResponse>(`${this._controllerName}/Conversation/${messengerConversationID}/Message`, req)
    }

    deleteMessage = (messengerConversationID: string, messageID: number) => {
        return this._axios.delete<any, _baseApiResponse<boolean>>(`${this._controllerName}/Conversation/${messengerConversationID}/DeleteMessage/${messageID}`)
    }

    /* Messenger Users */

    getUserList = (messengerGuid: string) => {
        return this._axios.get<any, MsnRes.UserListGet>(`${this._controllerName}/${messengerGuid}/UserList`);
    }

    userHasAccess = (messengerGuid: string) => {
        return this._axios.get<any, MsnRes.MemberPermissionGet>(`${this._controllerName}/${messengerGuid}/UserHasAccess`);
    }

    banUser = (messengerGuid: string, memberGuid: string) => {
        return this._axios.post<any, _baseApiResponse<boolean>>(`${this._controllerName}/${messengerGuid}/Ban/${memberGuid}`);
    }

    unbanUser = (messengerGuid: string, memberGuid: string) => {
        return this._axios.post<any, _baseApiResponse<boolean>>(`${this._controllerName}/${messengerGuid}/UnBan/${memberGuid}`);
    }

    /* Conversation Users */
    addParticipant = (messengerConversationID: string, participant: MsnReq.IEditMessangerUser) => {
        return this._axios.post<MsnReq.IEditMessangerUser, _baseApiResponse>(`${this._controllerName}/Conversation/${messengerConversationID}/Participant`, participant);
    }

    editParticipant = (messengerConversationID: string, participant: MsnReq.IEditMessangerUser) => {
        return this._axios.put<MsnReq.IEditMessangerUser, _baseApiResponse>(`${this._controllerName}/Conversation/${messengerConversationID}/Participant`, participant);
    }

    deleteParticipant = (messengerConversationID: string, memberID: number) => {
        return this._axios.delete<_baseApiResponse>(`${this._controllerName}/Conversation/${messengerConversationID}/Participant/${memberID}`);
    }

    /* Interactions */
    updateLastInteraction = (messengerConversationID: string) => {
        return this._axios.post<undefined, _baseApiResponse>(`${this._controllerName}/Conversation/${messengerConversationID}/UpdateLastInteraction`);
    }

    /* Reactions */
    getAllReactions = (messengerGuid: string) => {
        return this._axios.get<any, MsnRes.ReactionsGet>(`${this._controllerName}/${messengerGuid}/Reactions`);
    }

    addReaction = (messengerConversationID: string, reaction: MsnReq.IReaction) => {
        return this._axios.post<MsnReq.IReaction, _baseApiResponse<number>>(`${this._controllerName}/Conversation/${messengerConversationID}/AddReaction`, reaction);
    }

    deleteReaction = (messengerConversationID: string, reactionID: number) => {
        return this._axios.delete<any, _baseApiResponse<boolean>>(`${this._controllerName}/Conversation/${messengerConversationID}/DeleteReaction/${reactionID}`);
    }

    /* Preferences */
    hideConversation = (messengerConversationID: string) => {
        return this._axios.post<any, _baseApiResponse<boolean>>(`${this._controllerName}/Conversation/${messengerConversationID}/Hide`);
    }

    showConversation = (messengerConversationID: string) => {
        return this._axios.post<any, _baseApiResponse<boolean>>(`${this._controllerName}/Conversation/${messengerConversationID}/Show`);
    }

    showAllConversation = (messengerGuid: string) => {
        return this._axios.post<any, _baseApiResponse<boolean>>(`${this._controllerName}/${messengerGuid}/ShowAllConversation`);
    }

    pinConversation = (messengerConversationID: string) => {
        return this._axios.post<any, _baseApiResponse<boolean>>(`${this._controllerName}/Conversation/${messengerConversationID}/Pin`);
    }

    unPinConversation = (messengerConversationID: string) => {
        return this._axios.post<any, _baseApiResponse<boolean>>(`${this._controllerName}/Conversation/${messengerConversationID}/UnPin`);
    }

    /* Logs */
    saveLog = (messengerGuid: string, messengerLog: MsnReq.IMessengerLog) => {
        return this._axios.post<MsnReq.IMessengerLog, _baseApiResponse>(`${this._controllerName}/${messengerGuid}/SaveLog`, messengerLog);
    }
}

export default Messenger;