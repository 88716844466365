export module AuthReq
{
    export interface ILogin {
        userName: string,
        password: string,
        rememberMe: boolean,        
        authenticationMode: IAuthenticationMode,
    }

    export enum IAuthenticationMode {
        Local = 1,
        API = 2,
        SSO = 3
    }
}