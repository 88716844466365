import React, { useContext, useEffect, useRef, useState } from "react";
import MPAApi from "../../../API/MPAApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faPaperclip, faPaperPlane, faSmile, faTimes } from "@fortawesome/free-solid-svg-icons";
import { MsnReq } from "../../../API/RequestModels/Messenger";
import { ContextAppSettings } from "../../../config/context";
import { MsnRes } from "../../../API/ResponseModels/Messenger";
import QuotedMessage from "../QuotedMessage";
import { formatFileSize } from "../../../config/attachment";
import ChatAttachment from "../ChatAttachment";
import { ResourcesReq } from "../../../API/RequestModels/Resources";
import { theme } from "../../../config/theme";
import './ChatInputText.scss'
import { SessionStorageUtils } from "../../../config/function";
import { ResourcesRes } from "../../../API/ResponseModels/Resources";
import ChatInputContactSuggested from "./ChatInputContactSuggested";
import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react';
import { cloneDeep } from "lodash";
import { MessengerConversationTypeID } from "../../../API/Enum";
import useCurrentWidth from "../../_hooks/useCurrentWidth";
import useWindowOnClick from "../../_hooks/useWindowOnClick";
import { animated, useSpring } from "react-spring";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import Button from "../../Common/Button";
import UploadImageLink from "../Upload/UploadImageLink";

export interface IAttach {
  fileSize: string,
  fileName: string,
  fileType: string,
  file: File,
}

interface IInputMessages {
  key: string,
  message: string
}
interface IProps {
  userList: MsnRes.IUser[],
  conversation?: MsnRes.IConversation,
  quotedMessage: MsnRes.IMessage | null,
  updateConversation: Function,
  removeQuotedMessage: Function,
  images: ResourcesRes.IBase64[],
}

let enableSendMessage = true;
const ChatInputText = ({ userList, conversation, quotedMessage, updateConversation, removeQuotedMessage, images }: IProps) => {
  const { memberGuid, selectedTheme } = useContext(ContextAppSettings);
  const [userListFiltered, setUserListFiltered] = useState<MsnRes.IUser[]>([]);
  const [usersTagged, setUsersTagged] = useState<MsnRes.IUser[]>([]);
  const [attachments, setAttachments] = useState<IAttach[]>([]);
  const [emojiOpened, setEmojiOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const emojiOpenedBefore = useRef<boolean>(false);
  const [userListVisible, setUserListVisible] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const inputArea = useRef<HTMLTextAreaElement>(null!);
  const fileUpload = useRef<HTMLInputElement>(null);
  const cursorPos = useRef<number>(0);
  const previousConversationID = useRef("");
  const width = useCurrentWidth();
  let isSmallDevice = width < 767.98;

  useWindowOnClick(
    (event: any) => {
      let elem = event.target;
      let show = false;
      if (elem.id !== "emoji-picker" && elem.id !== "emoji-picker-button") {
        while (elem.parentElement) {
          elem = elem.parentElement;
          if (elem.id === "emoji-picker" || elem.id === "emoji-picker-button") {
            show = true;
            break;
          }
        }
      } else {
        show = true;
      }
      if (!show) {
        setEmojiOpen(false)
      }
    }
  )

  useEffect(() => {
    let inputMessages: IInputMessages[] = SessionStorageUtils.GetItemValue("conversationInputMessage") || [];
    let currentInputMessage = text;
    if (previousConversationID.current !== conversation?.messengerConversationID) {
      if (previousConversationID.current !== '') {
        let index = inputMessages.findIndex(x => x.key === previousConversationID.current)
        if (index > -1) {
          inputMessages[index].message = currentInputMessage;
        } else {
          inputMessages.push({ key: previousConversationID.current, message: currentInputMessage })
        }
      }
      previousConversationID.current = conversation?.messengerConversationID || '';
      let index = inputMessages.findIndex(x => x.key === conversation?.messengerConversationID)
      if (index > -1) {
        setText(inputMessages[index].message);
      }
      else {
        setText("");
      }
      SessionStorageUtils.SetItem("conversationInputMessage", inputMessages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation])

  useEffect(() => {
    if (userListVisible) {
      let _contactSuggested = text.substring(text.substring(0, cursorPos.current).lastIndexOf('@'), cursorPos.current);
      onFilterUserList(_contactSuggested.replace('@', ''));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListVisible, text])

  useEffect(() => {
    if (text === "" && !enableSendMessage) {
      enableSendMessage = true;
    }
  }, [text]);

  const handleOnChangeText = (text: string) => {
    cursorPos.current = (inputArea.current && inputArea.current.selectionStart) || 0;

    if (!text) {
      setUserListVisible(false);
    }
    else if ((conversation?.messengerConversationTypeID === MessengerConversationTypeID.Main ||
      conversation?.messengerConversationTypeID === MessengerConversationTypeID.Group)
      && (text === '@' || text.substring(cursorPos.current - 2, cursorPos.current) === ' @')) {
      setUserListVisible(true);
    }
    setText(text);
  }

  const onClickToSend = () => {
    handleOnEnter();
  }

  const onFilterUserList = (filter: string) => {
    let _userList = cloneDeep(userList).filter(x => x.memberGuid !== memberGuid && x.userPrivateMessage);

    if (filter) {
      filter = filter.toUpperCase();

      let usersFiltered = _userList.filter(x => x.userFirstName.toUpperCase().includes(filter) ||
        x.userLastName.toUpperCase().includes(filter) ||
        (x.userFirstName.toUpperCase() + ' ' + x.userLastName.toUpperCase()).includes(filter));

      setUserListFiltered(usersFiltered);
    }
    else {
      setUserListFiltered(_userList);
    }
  }

  const handleOnEnter = () => {
    if (((text && text.trim()) || (attachments && attachments.length > 0)) && conversation && enableSendMessage) {
      enableSendMessage = false;
      // If it's a new conversation, we first call the api service to create 
      if (conversation.messengerConversationID.startsWith("conv_")) {
        let newConversation: MsnReq.IConversation = {
          messengerGuid: conversation.messengerGuid,
          messengerConversationTypeID: conversation.messengerConversationTypeID,
          firstMemberGuid: conversation.firstMemberGuid,
          secondMemberGuid: conversation.secondMemberGuid,
          conversationTitle: conversation.messengerConversationTypeID === MessengerConversationTypeID.OneToOne ?
            "Private conversation" : conversation.conversationTitle,
          conversationSubtitle: conversation.conversationSubtitle,
          previewImageResourceID: conversation.previewImageResourceID,
          participants: conversation.participants,
        }
        MPAApi.Messenger.addConversation(newConversation)
          .then(res => {
            const response = res.data;
            if (response.status === "success") {
              let conversationID = response.data.id;
              updateConversation(conversation.messengerConversationID, conversationID);
              sendMessage(conversationID);
              removeQuotedMessage();
              removeAttachments();
              MPAApi.Messenger.saveLog(conversation.messengerGuid, { messengerConversationID: conversationID, action: "CreateConversation", agent: navigator.userAgent });
            }
          })
          .catch(e => console.error("addConversation - error", e))
      }
      else if (conversation.messengerConversationID) {
        sendMessage(conversation.messengerConversationID);
        removeQuotedMessage();
        removeAttachments();
      }
    }
  }

  const handleSelectUserFromAt = (user: MsnRes.IUser) => {
    let atPost = text.substring(0, cursorPos.current).lastIndexOf('@');
    const newText = text.substring(0, atPost) + '@' + user.userFirstName + ' ' + user.userLastName + ' ' + text.substring(cursorPos.current);
    setText(newText);
    setUserListVisible(false);
    setUsersTagged([...usersTagged, user]);
    cursorPos.current = newText.length;
    inputArea.current.focus();
  }

  const sendMessage = async (conversationID: string) => {
    let sendMessage = true;
    const req: MsnReq.IEditMessengerMessage = {
      memberGuid: memberGuid,
      content: getMessageWithTag(),
      quotedMessage: quotedMessage ? quotedMessage.messengerMessageID : 0,
      // timestamp: new Date(),
      archived: false,
      anonymous: false,
      resourcesID: [],
      memberGuidsTagged: usersTagged ? usersTagged.filter(x => !x.disableNotifications).map(x => x.memberGuid) : []
    }

    if (conversation && conversation.attachmentsFolder && conversation.attachmentsFolder > 0 && attachments && attachments.length > 0) {
      let resources: number[] = [];
      await Promise.all(attachments.map(x => {
        let resource: ResourcesReq.IEditResource = {
          attachedFile: x.file,
          resourceTypeID: '',
          resourceCategoryID: conversation && conversation.attachmentsFolder ? conversation.attachmentsFolder : 0,
          title: x.fileName,
          public: true,
          published: true,
          isExternal: false,
          priority: 0,
        }
        return MPAApi.Resource.uploadResource(resource)
      })).then(res => {
        if (res.length > 0) {
          res.forEach(x => {
            const response = x.data;
            if (response.status === "success") {
              resources.push(response.data);
            } else {
              sendMessage = false;
            }
          })
        }
      }).catch(e => { sendMessage = false; console.error("uploadResource - error", e); });
      req.resourcesID = resources;
    }

    if (sendMessage) {
      MPAApi.Messenger.sendMessage(conversationID, req)
        .then(res => {
          const response = res.data;
          if (response.status === 'success') {
            setUsersTagged([]);
            if (conversation && conversation.messengerGuid)
              MPAApi.Messenger.saveLog(conversation.messengerGuid, { messengerConversationID: conversationID, action: "SendMessage", agent: navigator.userAgent });
          }
          setText('');
        })
        .finally(() => {
          textAreaAdjust()
          if (text === "" && !enableSendMessage) {
            enableSendMessage = true;
          }
        })
        .catch(e => console.error("sendMessage - error", e))
    }
  }

  // Manage attachments:

  const handleUpload = () => {
    if (fileUpload && fileUpload.current) {
      fileUpload.current.click();
    }
  }

  const handleUploadLink = () => {
    setShow(true);
  }

  const handleAttachChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let attachments: IAttach[] = [];
    let files = e.target.files;
    if (files && files.length > 0) {
      Array.from(files).forEach(x => {
        attachments.push({
          file: x,
          fileSize: formatFileSize(x.size),
          fileName: x.name,
          fileType: x.type,
        })
      });
    }
    setAttachments(attachments);
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (cursorPos.current > 0 && e.key === "Backspace") {
      let atPos = text.substring(0, cursorPos.current).lastIndexOf('@');
      if (atPos > -1) {
        let userTagged = text.substring(atPos, cursorPos.current);
        if (userTagged && usersTagged.findIndex(x => '@' + x.userFirstName + ' ' + x.userLastName === userTagged) > -1) {
          inputArea.current.setSelectionRange(atPos, cursorPos.current);
        }
      }
    }
    if (e.ctrlKey && e.code === "Enter") {
      setTimeout(() => {
        //to handle multiple send in milliseconds
      }, 1000);
      onClickToSend();
    }
    textAreaAdjust();
  }

  const getMessageWithTag = () => {
    let newText = text;
    userList.forEach(u => {
      newText = newText.replace('@' + u.userFirstName + ' ' + u.userLastName, '<b>@' + u.userFirstName + ' ' + u.userLastName + "</b>")
    })
    return newText;
  }

  const textAreaAdjust = () => {
    if (inputArea && inputArea.current && inputArea.current.scrollHeight < 300) {
      inputArea.current.style.height = "1px";
      inputArea.current.style.height = (inputArea.current.scrollHeight) + "px";
    }
  }

  const removeAttachments = () => {
    setAttachments([]);
  }

  const onEmojiClick = (event: any, emojiObject: any) => {
    if (emojiOpened) {
      let prevText = text;
      setText(prevText + emojiObject.emoji);
    }
  };

  const handleOpenEmoji = () => {
    emojiOpenedBefore.current = true;
    setEmojiOpen(!emojiOpened);
  }

  const style = useSpring({
    from:
    {
      opacity: emojiOpenedBefore.current ? (emojiOpened ? "0" : "1") : "0",
      transform: "translateY(" + (emojiOpened ? "20px" : "-20px") + ")",
      zIndex: emojiOpenedBefore.current ? (emojiOpened ? 1 : -1) : -1,
    },
    to:
    {
      opacity: emojiOpenedBefore.current ? (emojiOpened ? "1" : "0") : "0",
      transform: "translateY(" + (emojiOpened ? "-20px" : "20px") + ")",
      zIndex: emojiOpenedBefore.current ? (emojiOpened ? 1 : -1) : -1,
    },
    config: {
      mass: 1,
      tension: 200,
      friction: 15
    },
  })

  return (
    <>
      {
        conversation?.messengerConversationID &&
        <>
          {
            userListVisible &&
            <div className="row mb-1">
              <div className="users-list-container">
                {
                  userListFiltered.map(x => {
                    return (
                      <ChatInputContactSuggested key={x.memberGuid} user={x} image={images.find(y => y.resourceID === x.userProfilePicture)}
                        handleSelectUserFromAt={handleSelectUserFromAt} />
                    )
                  })
                }
              </div>
            </div>
          }
          {
            quotedMessage &&
            <div className="row mb-1">
              <div className="col-12 quoted-message-container">
                {/* <span className="font-size-10">In reply to...</span> */}
                <QuotedMessage user={userList.find(x => x.memberGuid === quotedMessage.memberGuid)} message={quotedMessage} removeQuotedMessage={removeQuotedMessage} />
              </div>
            </div>
          }
          {
            attachments && attachments.length > 0 &&
            <div className="row" >
              <div className="col-12 d-flex flex-row" >
                <div className="ms-2 p-1 bg-color-chat-attachments d-flex flex-row">
                  {
                    attachments.map((x, index) =>
                      <ChatAttachment key={index} attach={x} />
                    )
                  }
                </div>
                <div className="ms-auto tx-color-chat-attachments-close">
                  <FontAwesomeIcon icon={faTimes} className="cursor-pointer font-size-12" onClick={removeAttachments} />
                </div>
              </div>
            </div>
          }
          <div className={`chat-input-wrapper border border-2 d-flex align-items-center ${isSmallDevice ? "ps-0 pe-0" : ""}`}>
            <div className="chat-input d-flex w-100" style={{ borderColor: theme.input_emoji_border_color[selectedTheme] }}>
              <textarea ref={inputArea} className="chat-input__text border-0 w-100 font-size-10" value={text} placeholder="Type a message ..." maxLength={500} rows={4}
                onChange={(e) => handleOnChangeText(e.target.value)}
                onKeyUp={(e) => handleKeyUp(e)}
              // onClick={(e) => console.log('inputArea.current.selectionStart', inputArea.current.selectionStart)}ù
              >
              </textarea>
            </div>
            <animated.div id="emoji-picker" className="emoji-picker-react-wrapper" style={style}>
              <Picker disableSkinTonePicker={true} onEmojiClick={onEmojiClick} skinTone={SKIN_TONE_NEUTRAL} />
            </animated.div>
            <input ref={fileUpload} id="uploadFile" className="d-none" type="file" onChange={handleAttachChange} onClick={(e) => { e.currentTarget.value = "" }} multiple />
            <div className={`chat-input__buttons d-flex ${isSmallDevice ? "ms-1" : "ms-4"} mt-2 mb-auto me-1`}>
              <FontAwesomeIcon className="mt-auto mb-auto me-1 cursor-pointer" id="emoji-picker-button" icon={faSmile} size={isSmallDevice ? "1x" : "lg"} color="grey" onClick={handleOpenEmoji} />
              <FontAwesomeIcon className={`mt-auto mb-auto ${isSmallDevice ? "ms-1" : "ms-4"} me-1 cursor-pointer`} icon={faLink} size={isSmallDevice ? "1x" : "lg"} color="grey" onClick={handleUploadLink} />
              <FontAwesomeIcon className={`mt-auto mb-auto ${isSmallDevice ? "ms-1" : "ms-4"} me-1 cursor-pointer`} icon={faPaperclip} size={isSmallDevice ? "1x" : "lg"} color="grey" onClick={handleUpload} />
              <FontAwesomeIcon className={`${isSmallDevice ? "ms-1" : "ms-4"} mt-auto mb-auto me-1`} icon={faPaperPlane} size={isSmallDevice ? "1x" : "lg"} color="grey" cursor="pointer" onClick={onClickToSend} />
            </div>
          </div>
          <UploadImageLink conversation={conversation} show={show} setShow={setShow}></UploadImageLink>
        </>
      }
    </>
  )
}

export default ChatInputText;