export enum MessengerConversationTypeID {
       Main = 1,
       OneToOne = 2,
       Group = 3,
}

export enum MessengerAction {
       Login = 'Login',
       Message = 'Message',
       Command = "Command",
       UserConnected = 'UserConnected',
       // DeleteMessage = 'DeleteMessage',
}

export enum MessengerCommand {
       Conversation = 'Conversation',
       UpdateConversation = 'UpdateConversation',
       DeleteMessage = 'DeleteMessage',
       UpdateUserStatus = 'UpdateUserStatus',
       NewReaction = 'NewReaction',
       DeleteReaction = 'DeleteReaction',
       BanUser = 'BanUser',
       UnbanUser = 'UnbanUser',
}

export enum Tabs {
       Chat = 'Chat',
       Contacts = 'Contacts'
}