import { useEffect, useLayoutEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Lightbox from 'react-image-lightbox';
import { MsnRes } from '../../API/ResponseModels/Messenger';
import './ChatUserInfo.scss'
import InfoCircle from '../Common/InfoCircle';
import useCurrentWidth from '../_hooks/useCurrentWidth';

interface IProps {
    messageID: number,
    showUserInfo: boolean,
    setShowUserInfo: Function,
    user?: MsnRes.IUser,
    picture?: string,
}

const ChatUserInfo = ({ messageID, user, showUserInfo, setShowUserInfo, picture }: IProps) => {

    const [isProfilePictureOpen, setProfilePictureOpened] = useState(false);
    let width = useCurrentWidth();

    useEffect(() => {
        adjustPosition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageID]);

    useLayoutEffect(() => {
        adjustPosition();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    const adjustPosition = () => {
        // Check scroll position
        const message: HTMLInputElement = document.getElementById('chat-message-' + messageID) as HTMLInputElement;
        const userInfo: HTMLInputElement = document.getElementById('chat-user-info') as HTMLInputElement;
        if (message && userInfo) {
            let contactButtonRect = message.getBoundingClientRect();
            if (contactButtonRect) {
                userInfo.style.top = contactButtonRect.bottom + document.documentElement.scrollTop + 10 + "px";
                userInfo.style.left = contactButtonRect.left + "px";
                userInfo.style.right = "unset";
            }
        }
    };

    const onClose = () => {
        setShowUserInfo(false);
    }

    return (
        <div id={"chat-user-info"} className={"chat-user-info " + (showUserInfo ? "show" : "hide")}>
            <InfoCircle className="bg-color-info-circle-message ms-1 flex-shrink-0" picture={picture}
                userName={user && (user?.userFirstName.charAt(0) + user?.userLastName.charAt(0))}
            />
            {user &&
                <div className={"chat-user-info-body"}>
                    <div className={"chat-user-info-body__name font-size-10"}>
                        <b className={"chat-user-info-body__b"}>{user.userTitle ? user.userTitle + ". " : ""}{user.userFirstName + " " + user.userLastName}</b>
                        <FontAwesomeIcon icon={faTimes} className={"chat-user-info-body__close font-size-10"} onClick={onClose} />
                    </div>
                    <div className={"chat-user-info-body__clinic font-size-8"}>
                        {user.userHospital} - {user.userCity}, {user.userCountry}
                    </div>
                    {
                        user.userWiOInterests && user.userWiOInterests.length > 0 &&
                        <div className={"chat-user-info-body__specialities u-font-size-8"}>
                            <b className={"chat-user-info-body__b"}>Orthopedic specialities: </b>{user.userWiOInterests.map((interest, index) =>
                                (interest + (index !== (user.userWiOInterests.length - 1) ? ", " : ""))
                            )}
                        </div>
                    }
                    {
                        user.userInterests && user.userInterests.length > 0 &&
                        <div className={"chat-user-info-body__interest u-font-size-8"}>
                            <b className={"chat-user-info-body__b"}>Areas of interest: </b>{user.userInterests.map((interest, index) =>
                                (interest + (index !== (user.userInterests.length - 1) ? ", " : ""))
                            )}
                        </div>
                    }
                </div>
            }
            {
                /* Image modal - open picture inserted */
                isProfilePictureOpen && picture &&
                <Lightbox
                    mainSrc={picture}
                    onCloseRequest={() => setProfilePictureOpened(false)}
                    enableZoom={false}
                />
            }
        </div>
    )
}

export default ChatUserInfo;