
import { useEffect, useState } from 'react';
import { MsnRes } from '../../API/ResponseModels/Messenger';
import { ResourcesRes } from '../../API/ResponseModels/Resources';
import ChatMessageReaction from '../../components/Chat/ChatMessageReaction';
import './ChatMessageReactions.scss'
import { ISelectedReaction } from './ChatMessages';

interface IProps {
    selectedReaction: ISelectedReaction,
    ownMessage: boolean,
    userList: MsnRes.IUser[],
    reactionTypes: MsnRes.IReactionType[],
    reactions: MsnRes.IReaction[],
    images: ResourcesRes.IBase64[],
}

interface IReactionsGrouped {
    reactionID: number,
    messageID: number,
    messengerReactionTypeID: number,
    imageURL?: string,
    memberIds: string[],
    count: number,
}

const ChatMessageReactions = ({ selectedReaction, ownMessage, userList, reactionTypes, reactions, images }: IProps) => {
    const [reactionsGrouped, setReactionsGrouped] = useState<IReactionsGrouped[]>();

    if (window.logRender) console.log("ChatMessageReactions - render", reactions.length > 0 ? reactions[0].messengerMessageID : 0);

    useEffect(() => {
        let group: IReactionsGrouped[] = [];
        reactions.forEach(x => {
            if (!group.find(y => y.messengerReactionTypeID === x.messengerReactionTypeID)) {
                group.push({
                    reactionID: x.messengerReactionTypeID,
                    messageID: x.messengerMessageID,
                    messengerReactionTypeID: x.messengerReactionTypeID,
                    imageURL: reactionTypes.find(z => z.messengerReactionTypeID === x.messengerReactionTypeID)?.imageURL,
                    memberIds: [x.memberGuid],
                    count: 1
                });
            }
            else {
                let elFound = group.find(y => y.messengerReactionTypeID === x.messengerReactionTypeID);
                if (elFound) {
                    elFound.count += 1;
                    elFound.memberIds.push(x.memberGuid);
                }
            }
        });
        group.sort((a, b) => { return a.count < b.count ? 1 : -1 });
        setReactionsGrouped(group);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reactions]);

    return (
        <div className={`chat-message-reactions-wrapper ${ownMessage ? "own" : ""}`}>
            <div className="chat-message-reactions">
                {
                    reactionsGrouped && reactionsGrouped.map((x, index) => {
                        return (
                            <ChatMessageReaction key={`reaction-tot_${x.reactionID}"_"${x.messageID}`} selectedReaction={selectedReaction} reactionID={x.reactionID} messageID={x.messageID} ownMessage={ownMessage} userList={userList} imageURL={x.imageURL} memberIds={x.memberIds} count={x.count}
                                images={images} />
                        )
                    })
                }
            </div>
        </div>
    );
}

export default ChatMessageReactions;