import { useContext, useState } from 'react';
import { MessengerConversationTypeID } from '../../API/Enum';
import { MsnRes } from '../../API/ResponseModels/Messenger';
import { ResourcesRes } from '../../API/ResponseModels/Resources';
import ChatHeader from '../../components/Chat/ChatHeader';
import ChatInputBox from '../../components/Chat/Input/ChatInputBox';
import { ContextAppSettings } from '../../config/context';
import { getOnlineStatus, getPreviewImage } from '../../config/function';
import './ChatContainer.scss'
import ChatMessages from './ChatMessages';

interface IProps {
    userList: MsnRes.IUser[],
    images: ResourcesRes.IBase64[],
    conversation?: MsnRes.IConversation,
    updateConversation: Function,
    showPanel: boolean,
}

const ChatContainer = ({ userList, images, conversation, updateConversation, showPanel }: IProps) => {
    const { memberGuid } = useContext(ContextAppSettings);
    const [quotedMessage, setQuotedMessage] = useState<MsnRes.IMessage | null>(null);

    const conversationID = conversation ? conversation.messengerConversationID : "";
    let image;
    if (conversation) {
        image = getPreviewImage(conversation, memberGuid, userList, images);
    }
    let title = conversation ? conversation.conversationTitle : "";
    let conversationType = conversation?.messengerConversationTypeID;
    let description = "";
    // Check if user is online or offline:
    if (conversationType === MessengerConversationTypeID.OneToOne) {
        let online = false;
        if (conversation) {
            online = getOnlineStatus(conversation, memberGuid, userList);
        }
        description = online ? "Online" : "Offline";
    }

    const handleQuoteMessage = (message: MsnRes.IMessage) => {
        setQuotedMessage(message);
    }

    const removeQuotedMessage = () => {
        setQuotedMessage(null);
    }

    return (
        <>
            <div className="chat-container-wrapper flex-grow-1">
                <div className="chat-container h-100">
                    <ChatHeader conversation={conversation} picture={image ? image.content : ""} title={title} description={description} conversationTypeID={conversation?.messengerConversationTypeID} />
                    <ChatMessages key={conversationID} userList={userList} images={images} conversationID={conversationID} handleQuoteMessage={handleQuoteMessage} showPanel={showPanel} />
                    <ChatInputBox userList={userList} images={images} conversation={conversation} updateConversation={updateConversation} quotedMessage={quotedMessage} removeQuotedMessage={removeQuotedMessage} />
                </div>
            </div>
        </>
    )
}

export default ChatContainer;