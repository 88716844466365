import ChatInputText from './ChatInputText';
import { MsnRes } from '../../../API/ResponseModels/Messenger';
import './ChatInputBox.scss';
import { ResourcesRes } from '../../../API/ResponseModels/Resources';
import { MessengerConversationTypeID } from '../../../API/Enum';
import { useContext, useEffect, useState } from 'react';
import useCurrentWidth from '../../_hooks/useCurrentWidth';
import { ContextAppSettings } from '../../../config/context';
import ChatInputBoxDisabled from './ChatInputBoxDisabled';
import { translations } from '../../../config/translations';

interface IProps {
    userList: MsnRes.IUser[],
    conversation?: MsnRes.IConversation,
    quotedMessage: MsnRes.IMessage | null,
    updateConversation: Function,
    removeQuotedMessage: Function,
    images: ResourcesRes.IBase64[],
}

const ChatInputBox = ({ userList, conversation, quotedMessage, updateConversation, removeQuotedMessage, images }: IProps) => {
    const [userListSuggested, setUserListSuggested] = useState<MsnRes.IUser[]>([]);
    const width = useCurrentWidth();
    const [textDisabled, setTextDisabled] = useState('');
    const { memberGuid, language } = useContext(ContextAppSettings);
    let isSmallDevice = width < 767.98;

    useEffect(() => {
        if (conversation?.messengerConversationTypeID === MessengerConversationTypeID.Main) {
            setUserListSuggested(userList);
        }
        if (conversation?.messengerConversationTypeID === MessengerConversationTypeID.Group) {
            setUserListSuggested(userList.filter(x => conversation.participants.filter(y => y.memberGuid === x.memberGuid)));
        }

        // Message box for disabled conversation:
        if (conversation?.messengerConversationTypeID === MessengerConversationTypeID.OneToOne) {
            console.log("conversation", conversation)
            if (conversation.isBlocked) {
                console.log("disable", translations.input_box_disabled.conversation_disabled[language])
                setTextDisabled(translations.input_box_disabled.conversation_disabled[language]);
            }
            else if (!userList.find(x => x.memberGuid === memberGuid)?.userPrivateMessage) {
                setTextDisabled(translations.input_box_disabled.me[language]);
            }
            else {
                let otherMemberGuid: string;
                if (conversation.firstMemberGuid === memberGuid) {
                    otherMemberGuid = conversation.secondMemberGuid;
                } else {
                    otherMemberGuid = conversation.firstMemberGuid;
                }
                if (!userList.find(x => x.memberGuid === otherMemberGuid)?.userPrivateMessage) {
                    setTextDisabled(translations.input_box_disabled.other[language]);
                }
                else if (userList.find(x => x.memberGuid === otherMemberGuid)?.isBanned) {
                    setTextDisabled(translations.input_box_disabled.user_banned[language]);
                }
                else {
                    setTextDisabled('')
                }
            }
        }
    }, [userList, conversation])

    return (
        <section className={`row w-100 justify-content-center bg-color-chat-input-box ms-0 ${isSmallDevice ? "p-1" : "p-2"}`}>
            {
                conversation?.messengerConversationTypeID === MessengerConversationTypeID.OneToOne && textDisabled ?
                    <ChatInputBoxDisabled message={textDisabled} isSmallDevice={isSmallDevice} />
                    :
                    <ChatInputText userList={userListSuggested} images={images} conversation={conversation}
                        quotedMessage={quotedMessage} updateConversation={updateConversation} removeQuotedMessage={removeQuotedMessage} />
            }
        </section>
    )
}

export default ChatInputBox;
