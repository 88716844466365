// DEFINE GLOBAL VARIABLES:
// API:
const API_DEV: string = "https://localhost:5001/api/";
//const API_DEV: string = "http://api-messenger.zbsmartspace.devserver/api/";
//const API_DEV: string = "http://api-messenger.zbinetworkemea.com/api/";
const API_TEST: string = "https://api-messenger.zbsmartspace.devserver/api/";
//const API_PROD: string = "https://api-messenger.zbsmartspace.devserver/api/";
const API_PROD: string = "https://api-messenger.zbinetworkemea.com/api/";

// EXTERNAL API:
const EXT_API_DEV: string = "http://localhost:55159/api/";
//const EXT_API_DEV: string = "http://api.zbsmartspace.devserver/api/";
const EXT_API_TEST: string = "https://api-test.zbinetworkemea.com/api/";
//const EXT_API_TEST: string = "https://api-messenger.zbsmartspace.devserver/api/";
const EXT_API_PROD: string = "https://api.zbinetworkemea.com/api/";

// WEBSOCKET: 
const WS_DEV: string = "https://localhost:5001/WebSocket";
//const WS_DEV: string = "http://api-messenger.zbsmartspace.devserver/WebSocket";
//const WS_DEV: string = "http://api-messenger.zbinetworkemea.com/WebSocket";
const WS_TEST: string = "https://api-messenger.zbsmartspace.devserver/WebSocket";
//const WS_PROD: string = "https://api-messenger.zbsmartspace.devserver/WebSocket";
const WS_PROD: string = "https://api-messenger.zbinetworkemea.com/WebSocket";

// SESSION:
const SESSION_DEV: number = 0.5;
const SESSION_TEST: number = 20;
const SESSION_PROD: number = 30;

const DEFAULT_REDIRECT = "/login";

//Session variables are setted up in _redux/slices/UISettingsSlice.ts

const APIUrl = () => {
    let result: string | undefined = "/";

    if (process.env.NODE_ENV === 'development') result = API_DEV;
    else if (process.env.REACT_APP_STATE === 'test') result = API_TEST;
    else result = API_PROD;

    return result;
}

const ExternalAPIUrl = () => {
    let result: string | undefined = "/";

    if (process.env.NODE_ENV === 'development') result = EXT_API_DEV;
    else if (process.env.REACT_APP_STATE === 'test') result = EXT_API_TEST;
    else result = EXT_API_PROD;

    return result;
}

const WSUrl = () => {
    let result: string | undefined = "/";

    if (process.env.NODE_ENV === 'development') result = WS_DEV;
    else if (process.env.REACT_APP_STATE === 'test') result = WS_TEST;
    else result = WS_PROD;

    return result;
}

const SessionLength = () => {
    let result: number = 20;

    if (process.env.NODE_ENV === 'development') result = SESSION_DEV;
    else if (process.env.REACT_APP_STATE === 'test') result = SESSION_TEST;
    else result = SESSION_PROD;

    return result;
}

const EnableDebug = () => {
    let result: boolean = false;

    if (process.env.NODE_ENV === 'development') result = true;
    else if (process.env.REACT_APP_STATE === 'test') result = true;
    else result = false;

    return result;
}

export { APIUrl, ExternalAPIUrl, WSUrl, SessionLength, EnableDebug, DEFAULT_REDIRECT };