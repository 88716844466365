import React, { useEffect, useRef, useState } from 'react';
import ChatContainer from "./Chat/ChatContainer";
import MessengerPanel from "./MessengerPanel/MessengerPanel";
import './MessengerContainer.scss'
import MPAApi, { ExternalApi } from '../API/MPAApi';
import { useContext } from 'react';
import { ContextAppSettings } from '../config/context';
import { MsnRes } from '../API/ResponseModels/Messenger';
import { cloneDeep } from 'lodash';
import { MsnReq } from '../API/RequestModels/Messenger';
import { MessengerConversationTypeID } from '../API/Enum';
import { ResourcesRes } from '../API/ResponseModels/Resources';
import { WsRes } from '../API/ResponseModels/WebSocket';
import { checkConversationHidden, getConversationTitle, updateConversationListWebSocket } from '../config/function';
import { translations } from '../config/translations';
import useCurrentWidth from '../components/_hooks/useCurrentWidth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { animated, useSpring } from 'react-spring';

interface IProps {
    checkUserAccess: Function
}

const MessengerContainer = ({ checkUserAccess }: IProps) => {
    const { messengerGuid, language, memberGuid } = useContext(ContextAppSettings);
    const [conversationList, setConversationList] = useState<MsnRes.IConversation[]>([])
    const [userList, setUserList] = useState<MsnRes.IUser[]>([])
    const [activeConversation, setActiveConversation] = useState<MsnRes.IConversation>();
    const [images, setImages] = useState<ResourcesRes.IBase64[]>([]);
    const [isLoadingPicture, setLoadingPicture] = useState(true);
    const [animatedConversation, setAnimatedConversation] = useState("");
    const [showPanel, setShowPanel] = useState(false);
    const _showPanel = useRef(false);
    const isFirstLoading = useRef(true);
    const isLoadingConversation = useRef(false);
    const activeConversationID = useRef("");
    const width = useCurrentWidth();
    let isSmallDevice = width < 767.98;

    // Hooks:

    useEffect(() => {
        window.updateConversationPref = (conversationID: string, pinned: boolean, hidden?: Date) => updateConversationPref(conversationID, pinned, hidden);
        window.updateConversationList = (message: WsRes.IWsMessage) => updateConversationList(message);
        window.addConversation = (conversationID: string) => addConversationFromID(conversationID);
        window.updateConversation = (conversation: MsnRes.IConversation) => updateConversationFromWs(conversation);
        window.updateConversationListOnDeleteMessage = (messageID: string) => updateConversationListOnDeleteMessage(parseInt(messageID));
        window.updateUserStatus = (userStatus: WsRes.IWsUser) => updateUserStatus(userStatus);
        window.banUser = (memberGuid: string) => banUser(memberGuid);
        window.unbanUser = (memberGuid: string) => unbanUser(memberGuid);
        isFirstLoading.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, []);

    useEffect(() => {
        if (messengerGuid) {
            // Conversation: 
            let query: MsnReq.IQueryConversation = {
                getLastMessage: true,
            }
            MPAApi.Messenger.getConversations(messengerGuid, query)
                .then(res => {
                    const response = res.data;
                    if (response.status === "success") {
                        let _convList = response.data;
                        setConversationList(_convList);
                        if (_convList && _convList.length > 0) {
                            _convList = _convList.filter(x => !checkConversationHidden(x));
                            let activeMainConv = _convList.find(x => x.messengerConversationTypeID === MessengerConversationTypeID.Main)
                            if (activeMainConv) {
                                setActiveConversation(activeMainConv);
                                activeConversationID.current = activeMainConv && activeMainConv.messengerConversationID ? activeMainConv.messengerConversationID : "";
                            }
                            else {
                                setActiveConversation(_convList[0]);
                                activeConversationID.current = _convList[0] && _convList[0].messengerConversationID ? _convList[0].messengerConversationID : "";
                            }
                        }
                        getUserList();
                    }
                }).catch(e => console.error("getConversations - error", e))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messengerGuid]);

    useEffect(() => {
        if (!isLoadingPicture) {
            // Retrive all profile pictures and conversation images id:
            let resourcesID: number[] = [];
            if (userList.length > 0) {
                userList.filter(x => x.userProfilePicture > 0).forEach(x => {
                    if (resourcesID.findIndex(y => y === x.userProfilePicture) === -1) {
                        resourcesID.push(x.userProfilePicture)
                    }
                });
            }
            if (conversationList.length > 0) {
                conversationList.filter(x => x.previewImageResourceID > 0).forEach(x => {
                    if (resourcesID.findIndex(y => y === x.previewImageResourceID) === -1) {
                        resourcesID.push(x.previewImageResourceID)
                    }
                });
            }
            if (resourcesID.length > 0) {
                Promise.all(resourcesID.map(x =>
                    ExternalApi.Resource.getResourceBase64(x)
                )).then(res => {
                    let _images: ResourcesRes.IBase64[] = []
                    if (res.length > 0) {
                        res.forEach(x => {
                            const response = x.data;
                            if (response.status === "success") {
                                _images.push(response.data);
                            }
                        })
                        setImages(_images);
                    }
                }).catch(e => console.error("getResourceBase64 - error", e));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadingPicture]);

    useEffect(() => {
        if (activeConversation && activeConversation.messengerConversationID) {
            let _conversationList = cloneDeep(conversationList);
            let index = _conversationList.findIndex(x => x.messengerConversationID === activeConversation.messengerConversationID);
            if (index > -1) {
                _conversationList[index].newMessagesNumber = 0;
                setConversationList(_conversationList);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeConversation]);

    useEffect(() => {
        if (conversationList.length > 0 && userList.length > 0) {
            let _conversationList = [...conversationList];
            _conversationList.forEach(x => {
                x.conversationTitle = getConversationTitle(x, memberGuid, userList);
            });
            setConversationList(_conversationList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userList])

    // Function:

    const getUserList = () => {
        MPAApi.Messenger.getUserList(messengerGuid)
            .then(res => {
                const response = res.data;
                if (response.status === "success") {
                    setUserList(response.data);
                    setLoadingPicture(false);
                }
                else {
                    setLoadingPicture(false);
                }
            }).catch(e => { console.error("getConversations - error", e); setLoadingPicture(false); })
    }

    const addConversation = (conversation: MsnRes.IConversation) => {
        let alreadyExists = false;
        // Check if conversation already exists:        
        if (conversation.messengerConversationTypeID === MessengerConversationTypeID.OneToOne) {
            let index = conversationList.findIndex(x =>
                (x.firstMemberGuid === conversation.firstMemberGuid && x.secondMemberGuid === conversation.secondMemberGuid) ||
                (x.secondMemberGuid === conversation.firstMemberGuid && x.firstMemberGuid === conversation.secondMemberGuid));
            if (index > -1) {
                alreadyExists = true;
                setActiveConversation(conversationList[index]);
                activeConversationID.current = conversationList[index].messengerConversationID;
                if (!activeConversationID.current.startsWith("conv_")) {
                    deleteEmptyChat()
                }
            }
        }
        if (!alreadyExists) {
            let _conversationList = cloneDeep(conversationList);
            let index = _conversationList.findIndex(x => x.messengerConversationID.startsWith("conv_"));
            if (index > -1) {
                _conversationList[index] = conversation;
            } else {
                _conversationList.push(conversation);
            }
            setConversationList(_conversationList);
            setActiveConversation(conversation);
            activeConversationID.current = conversation.messengerConversationID;
        } else {
            if (!conversation.messengerConversationID.startsWith("conv_")) {
                let _conversationList = cloneDeep(conversationList);
                setConversationList(_conversationList.filter(x => !x.messengerConversationID.startsWith("conv_")));
            }
        }
        setShowPanel(false);
    }

    const updateConversation = (oldConversationID: string, newConversationID: string) => {
        let _conversationList = cloneDeep(conversationList);
        // We remove temporary conversation created by clicking on a contact.
        setConversationList(_conversationList.filter(x => x.messengerConversationID !== oldConversationID));
        // Then we update current conversation ID so when the websocket receive the new conversation it's already selected
        activeConversationID.current = newConversationID;
    }

    const handleSetActiveConversation = (conversation: MsnRes.IConversation) => {
        setActiveConversation(conversation);
        activeConversationID.current = conversation.messengerConversationID;
        if (!conversation.messengerConversationID.startsWith("conv_")) {
            deleteEmptyChat();
        }
        setShowPanel(false);
    }

    const updateUserStatus = (userStatus: WsRes.IWsUser) => {
        setUserList(prev => {
            let _userList = [...prev];
            // New / Updated user:
            let _user = userStatus.user;
            // User logged in:
            if (_user) {
                _user.online = userStatus.online;
                let index = _userList.findIndex(x => x.memberGuid === userStatus.memberGuid);
                if (index > -1) {
                    _userList[index] = _user;
                }
                else {
                    _userList.push(_user);
                }
            }
            // User logged out:
            else {
                let index = _userList.findIndex(x => x.memberGuid === userStatus.memberGuid);
                if (index > -1) {
                    _user = cloneDeep(_userList[index]);
                    _user.online = userStatus.online;
                    _userList[index] = _user;
                }
            }
            return _userList;
        });
    }

    const deleteEmptyChat = () => {
        setConversationList(prev => {
            return prev.filter(x => !x.messengerConversationID.startsWith("conv_"))
        });
    }

    const updateConversationPref = (conversationID: string, pinned: boolean, hidden?: Date) => {
        setConversationList(prevConversations => {
            let _prevConversations = [...prevConversations];
            let index = _prevConversations.findIndex(x => x.messengerConversationID === conversationID);
            if (index > -1) {
                _prevConversations[index].conversationHidden = hidden;
                _prevConversations[index].pinned = pinned;
                _prevConversations[index].conversationPinned = pinned ? new Date() : undefined;
            }
            return _prevConversations;
        })
    }

    const toggleShowPanel = () => {
        setShowPanel(!showPanel);
    }

    // Websocket function:

    const addConversationFromID = (conversationID: string) => {
        isLoadingConversation.current = true;
        // Conversation: 
        let query: MsnReq.IQueryConversation = {
            getLastMessage: true,
        }
        MPAApi.Messenger.getConversation(conversationID, query)
            .then(res => {
                const response = res.data;
                if (response.status === "success") {
                    let conversation: MsnRes.IConversation = response.data;
                    conversation.conversationTitle = getConversationTitle(conversation, memberGuid, userList);
                    setConversationList(prevConversations => {
                        return [...prevConversations, conversation];
                    });
                    if (conversation.messengerConversationID === activeConversationID.current) {
                        setActiveConversation(conversation);
                    }
                    getUserList();
                    setAnimatedConversation(conversation.messengerConversationID);
                }
                setTimeout(() => {
                    isLoadingConversation.current = false;
                }, 300);
            }).catch(e => {
                console.error("getConversations - error", e);
                setTimeout(() => {
                    isLoadingConversation.current = false;
                }, 300);
            })
    }

    const updateConversationFromWs = (conversation: MsnRes.IConversation) => {
        setConversationList(prev => {
            let _conversationList = cloneDeep(prev);
            let index = _conversationList.findIndex(x => x.messengerConversationID === conversation.messengerConversationID);
            if (index > -1) {
                _conversationList[index].isBlocked = conversation.isBlocked;
                _conversationList[index].blockedByMemberGuid = conversation.blockedByMemberGuid;
            }
            return _conversationList;
        });
        if (activeConversationID.current === conversation.messengerConversationID) {
            setActiveConversation(prev => {
                let _activeConversation = cloneDeep(prev);
                if (_activeConversation) {
                    _activeConversation.isBlocked = conversation.isBlocked;
                    _activeConversation.blockedByMemberGuid = conversation.blockedByMemberGuid;
                }
                return _activeConversation;
            });
        }
    }

    const updateConversationList = (message: WsRes.IWsMessage) => {
        if (!isLoadingConversation.current) {
            setAnimatedConversation(message.conversationID);
            setConversationList(prevConversations => {
                return updateConversationListWebSocket(cloneDeep(prevConversations), message, activeConversationID.current);
            })
            if (activeConversationID.current === message.conversationID) {
                setActiveConversation(x => {
                    let y = cloneDeep(x);
                    if (y) {
                        y.lastMessage = message.message;
                        y.lastMessageDate = message.timestamp;
                    }
                    return y;
                });

            }
        } else {
            setTimeout(() => {
                updateConversationList(message);
            }, 300);
        }
    }

    const updateConversationListOnDeleteMessage = (messageID: number) => {
        setConversationList(prevConversations => {
            let _prevConversations = [...prevConversations];
            let index = _prevConversations.findIndex(x => x.lastMessageID === messageID);
            if (index > -1) {
                _prevConversations[index].lastMessageID = 0;
                _prevConversations[index].lastMessage = translations.chat.deleted_message[language];
                _prevConversations[index].lastMessageDate = new Date();
            }
            return _prevConversations;
        })
    }

    const banUser = (memberGuid: string) => {
        setUserList(prev => {
            let _userList = [...prev];
            let index = _userList.findIndex(x => x.memberGuid === memberGuid);
            if (index > -1) {
                _userList[index].isBanned = true;
            }
            return _userList;
        });
        checkUserAccess(messengerGuid);
    }

    const unbanUser = (memberGuid: string) => {
        setUserList(prev => {
            let _userList = [...prev];
            let index = _userList.findIndex(x => x.memberGuid === memberGuid);
            if (index > -1) {
                _userList[index].isBanned = false;
            }
            return _userList;
        });
        checkUserAccess(messengerGuid);
    }

    const style = useSpring({
        from: { transform: "translateX(" + (isSmallDevice ? _showPanel.current === showPanel ? (showPanel ? "0%" : "-100%") : (showPanel ? "-100%" : "0%") : "0") + ")" },
        to: { transform: "translateX(" + (isSmallDevice ? _showPanel.current === showPanel ? (showPanel ? "0%" : "-100%") : (showPanel ? "0%" : "-100%") : "0") + ")" },
        config: {
            duration: 500,
        },
        onRest: () => { console.log("rest"); _showPanel.current = showPanel; },
        reset: true,
    })

    return (
        <div className={`messenger-wrapper container-fluid ${isSmallDevice ? "p-1" : "p-3"} h-100 w-100`}>
            <div className="messenger position-relative h-100 w-100">
                <animated.div className="position-relative d-flex h-100 w-100" style={style}>
                    <MessengerPanel userList={userList} conversationList={conversationList}
                        images={images} activeConversation={activeConversation}
                        setActiveConversation={handleSetActiveConversation} addConversation={addConversation}
                        animatedConversation={animatedConversation} setAnimatedConversation={setAnimatedConversation}
                    />
                    <ChatContainer userList={userList} conversation={activeConversation}
                        images={images} updateConversation={updateConversation}
                        showPanel={showPanel} />
                </animated.div>
            </div>
            {
                isSmallDevice ?
                    !showPanel ?
                        <div className="messenger-panel-icon position-absolute bg-color-panel-icon d-flex cursor-pointer" onClick={() => toggleShowPanel()}>
                            <div className="m-auto">
                                <FontAwesomeIcon icon={faAngleDoubleRight} className="font-size-13" />
                            </div>
                        </div> :
                        <div className="chat-container-icon position-absolute bg-color-chat-icon d-flex cursor-pointer" onClick={() => toggleShowPanel()}>
                            <div className="m-auto">
                                <FontAwesomeIcon icon={faAngleDoubleLeft} className="font-size-15" />
                            </div>
                        </div>
                    :
                    null
            }
        </div>
    )
}

export default MessengerContainer;