import { AxiosInstance } from "axios";
import { AuthReq } from "../RequestModels/Auth";
import { AuthRes } from "../ResponseModels/Auth";

class Auth {
    private _controllerName: string = "Authentication";
    private _axios: AxiosInstance;

    constructor(axiosInstance: any) {
        this._axios = axiosInstance;
    }

    login = (data: AuthReq.ILogin) => {
        return this._axios.post<AuthReq.ILogin, AuthRes.LoginGet>(`${this._controllerName}/login`, data);
    }
}

export default Auth;