import './Loader.scss';

const Loader = () => {

    return (
        <div className="lds-ellipsis-wrapper">
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    )
}

export default Loader;
