import { APIUrl, ExternalAPIUrl } from '../config/config';
import AxiosClient from './AxiosClient';
import Auth from './controllers/AuthController';
import Messenger from './controllers/MessengerController';
import Resource from './controllers/ResourceController';

class MPAApiClass extends AxiosClient {
    Auth: Auth;
    Messenger: Messenger;
    Resource: Resource;

    constructor(apiUrl: string) {
        // Initialize instant based on configs
        super(apiUrl);

        // Controller initialization
        this.Auth = new Auth(this.instance);
        this.Messenger = new Messenger(this.instance);
        this.Resource = new Resource(this.instance);
    }
}

const MPAApi = new MPAApiClass(APIUrl());

export const ExternalApi = new MPAApiClass(ExternalAPIUrl());

export default MPAApi;