import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { ContextAppSettings } from "../../config/context";
import { translations } from "../../config/translations";

interface IProps {
    className: string,
    onChange: Function,
}
const SearchBox = ({ className, onChange }: IProps) => {
    const { language } = useContext(ContextAppSettings);

    return (
        <div className={"search-box d-flex border border-2 border-color-search " + className}>
            <FontAwesomeIcon className="ms-2 mt-auto mb-auto me-2 cursor-pointer" icon={faSearch} size="sm" />
            <input type="text" className="border-0 w-100" placeholder={translations.panel.search[language]} onChange={(e) => onChange(e)}></input>
        </div>
    )
}
export default SearchBox;