import { MsnRes } from '../../../API/ResponseModels/Messenger';
import './ChatInputBox.scss';

interface IProps {
    message: string,
    isSmallDevice: boolean,
    // conversation: MsnRes.IConversation,
}

const ChatInputBoxDisabled = ({ message, isSmallDevice }: IProps) => {
    return (
        <div className={`chat-input-disabled-wrapper d-flex align-items-center ${isSmallDevice ? "ps-0 pe-0" : ""}`}>
            <div className="chat-input d-flex w-100" style={{ borderColor: '#E0E0E0' }}>
                <span className="chat-input-disabled__text border-0 w-100 font-size-10">{message}</span>
                {/* <textarea className="chat-input-disabled__text border-0 w-100 font-size-10" value={message} placeholder="Type a message ..." maxLength={500} rows={4} style={{ backgroundColor: '#E0E0E0' }}>
                </textarea> */}
            </div>
        </div>
    )
}

export default ChatInputBoxDisabled;