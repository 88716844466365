import moment from "moment";
import { memo, useContext, useState } from "react";
import { ContextAppSettings } from "../../config/context";
import InfoCircle from "../Common/InfoCircle";
import './ChatMessage.scss'
import { MsnRes } from "../../API/ResponseModels/Messenger";
import Button from "../Common/Button";
import QuotedMessage from "./QuotedMessage";
import MPAApi from "../../API/MPAApi";
import ChatAttachment from "./ChatAttachment";
import { animated, useSpring } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../../config/theme";
import ChatMessageReactionsSuggested from "../../containers/Chat/ChatMessageReactionsSuggested";
import ChatMessageReactions from "../../containers/Chat/ChatMessageReactions";
import { ResourcesRes } from "../../API/ResponseModels/Resources";
import { ISelectedReaction } from "../../containers/Chat/ChatMessages";
import parse from 'html-react-parser';

interface IProps {
    user?: MsnRes.IUser,
    userList: MsnRes.IUser[],
    message: MsnRes.IMessage,
    quotedUser?: MsnRes.IUser,
    quotedMessage?: MsnRes.IMessage,
    picture: string,
    images: ResourcesRes.IBase64[],
    handleUserInfoClick: Function,
    handleQuoteMessage: Function,
    selectedReaction: ISelectedReaction,
    isSmallDevice: boolean,
}

const ChatMessage = ({ user, userList, message, quotedUser, quotedMessage, picture, images, handleUserInfoClick, handleQuoteMessage, selectedReaction, isSmallDevice }: IProps) => {
    const { memberGuid, selectedTheme, reactions } = useContext(ContextAppSettings);
    const ownMessage = message.memberGuid === memberGuid;
    const [showDeleteTrash, setShowDeleteTrash] = useState(false);

    if (window.logRender) console.log("ChatMessage - render", message.messengerMessageID);

    const handleDeleteMessage = (message: MsnRes.IMessage) => {
        MPAApi.Messenger.deleteMessage(message.messengerConversationID, message.messengerMessageID)
            .catch(e => { console.error("deleteMessage - error", e); })
    }

    const style = useSpring({
        from: isSmallDevice ? null : { opacity: 0, transform: "translate3d(" + (message.skipAnimation ? "0" : ownMessage ? "50%" : "-50%") + ", 0, 0)" },
        to: isSmallDevice ? null : { opacity: 1, transform: "translate3d(0, 0, 0)" }
    })

    return (
        <animated.div className="position-relative" style={{ ...style, zIndex: selectedReaction.messageID === message.messengerMessageID ? 1 : 0 }}>
            <div id={`chat-message-` + message.messengerMessageID} className={`chat-message-wrapper ${ownMessage ? 'own' : ''}`}>
                <div className={`chat-message tx-color-chat-message ${ownMessage ? 'own' : ''}`}>
                    <div className="chat-message-preview d-flex justify-content-center align-items-center flex-column">
                        <div className="mb-auto mt-1">
                            <InfoCircle className={(ownMessage ? "bg-color-info-circle-own-message" : "bg-color-info-circle-message cursor-pointer") + " ms-1"} picture={picture}
                                userName={user && (user?.userFirstName.charAt(0) + user?.userLastName.charAt(0))}
                            />
                            {!ownMessage && <Button id={"chat-user-info_button_" + message.messengerMessageID} withClass={["small"]} classes="rounded-button-message font-size-8" clicked={() => handleUserInfoClick(message)}>Info</Button>}
                        </div>
                    </div>
                    <div className={`chat-message-content position-relative ${ownMessage ? 'bg-color-chat-own-message me-2' : 'bg-color-chat-message ms-2'}`}>
                        {
                            !ownMessage && !showDeleteTrash && reactions && reactions.length > 0 &&
                            <ChatMessageReactionsSuggested reactions={reactions} message={message} />
                        }
                        {
                            showDeleteTrash &&
                            <div className="m-0 p-0 w-100 h-100 chat-message-delete">
                                <div className="chat-message-delete-trash-wrapper" onClick={() => handleDeleteMessage(message)}>
                                    <FontAwesomeIcon size="lg" className="chat-message-delete-trash" icon={faTrash} />
                                </div>
                                <Button withClass={["small"]} classes={"chat-message-delete-redo"} icon="faReply" clicked={() => setShowDeleteTrash(false)}><span className="chat-message-delete-redo__label">Undo</span></Button>
                            </div>
                        }
                        {
                            quotedMessage &&
                            <div className="ms-0 mt-2 mb-2">
                                <QuotedMessage user={quotedUser} message={quotedMessage} hideCloseButton={true} />
                            </div>
                        }
                        <div className="chat-message-header d-flex">
                            <div className="chat-message-header__timestamp font-size-7 mt-auto mb-auto">
                                {moment(message.timestamp).format(theme.date_format[selectedTheme])}
                            </div>
                            <div className="d-flex ms-auto">
                                <Button withClass={["small"]} classes={ownMessage ? "rounded-button-own-message ms-2 font-size-8" : "rounded-button-message ms-2 font-size-8"} icon="faReply" clicked={() => handleQuoteMessage(message)}>{isSmallDevice ? "" : "Quote"}</Button>
                                {
                                    (ownMessage || userList.find(x => x.memberGuid === memberGuid)?.isModerator) && !showDeleteTrash &&
                                    <Button withClass={["small"]} classes={ownMessage ? "rounded-button-own-message ms-2 font-size-8" : "rounded-button-message ms-2 font-size-8"} icon="faTimes" clicked={() => setShowDeleteTrash(true)}>{isSmallDevice ? "" : "Del"}</Button>
                                }
                            </div>
                        </div>

                        <hr className={`chat-message-separator ${ownMessage ? 'own' : ''}`}></hr>

                        <div className="chat-message-body">
                            <h2 className="chat-message-body__username font-size-10 font-weight-bold">{user?.userFirstName + ' ' + user?.userLastName}</h2>
                            <div className="d-flex">
                                <p className="chat-message-body__content font-size-8">{parse(message.content)}</p>
                            </div>
                        </div>
                        {
                            message.resources && message.resources.length > 0 &&
                            <div className="p-1 d-flex flex-column">
                                {
                                    message.resources.map((x, index) =>
                                        <ChatAttachment key={index} resource={x} />
                                    )
                                }
                            </div>
                        }
                    </div>
                    {
                        message.reactions && message.reactions.length > 0 && !showDeleteTrash &&
                        <ChatMessageReactions selectedReaction={selectedReaction} ownMessage={ownMessage} userList={userList} reactionTypes={reactions} reactions={message.reactions} images={images} />
                    }
                </div>
            </div>
        </animated.div>
    )
}

// For achieve a better performance, we re-render chat message only if there are reactions change, otherwise we won't re-render it!
export default memo(ChatMessage, (a, b) => {
    let prevReaction = a.message.reactions || [];
    let newReaction = b.message.reactions || [];
    if (prevReaction.length !== newReaction.length) {
        return false;
    } else {
        if (prevReaction.some(x => newReaction.findIndex(y => y.messengerMessageReactionID === x.messengerMessageReactionID) === -1) ||
            newReaction.some(x => prevReaction.findIndex(y => y.messengerMessageReactionID === x.messengerMessageReactionID) === -1)) {
            return false;
        }
    }
    if (JSON.stringify(a.user) !== JSON.stringify(b.user)) {
        return false;
    }
    if (a.picture !== b.picture) {
        return false;
    }
    let prevSelReaction = a.selectedReaction;
    let newSelReaction = b.selectedReaction;
    // Changed selected reaction message:
    if (prevSelReaction.messageID !== newSelReaction.messageID) {
        // Update new message related to the new selected reaction:
        if (a.message.messengerMessageID === newSelReaction.messageID) {
            return false;
        }
        // Update previous message related to previous selected reaction:
        if (prevSelReaction.messageID === b.message.messengerMessageID) {
            return false;
        }
    }
    // Changed selected reaction (same message)
    if (prevSelReaction.reactionID !== newSelReaction.reactionID) {
        // Update only the current message related to reaction:
        if (prevSelReaction.messageID === b.message.messengerMessageID) {
            return false;
        }
    }
    if (a.isSmallDevice !== b.isSmallDevice) {
        return false;
    }
    return true;
});