export type TMainLanguage = 'en' | 'de' | 'es' | 'it' | 'fr';

type IStringTranslations = {
    [key in TMainLanguage]: string;
};

interface IObjectTranslations {
    panel: {
        search: IStringTranslations,
        show_hidden_conv: IStringTranslations,
    },
    chat: {
        deleted_message: IStringTranslations,
        blocked: IStringTranslations,
    },
    menu: {
        pin: IStringTranslations,
        unpin: IStringTranslations,
        hide: IStringTranslations,
        unhide: IStringTranslations,
        ban: IStringTranslations,
        unban: IStringTranslations,
        block: IStringTranslations,
        unblock: IStringTranslations
    },
    input_box_disabled: {
        me: IStringTranslations,
        other: IStringTranslations,
        conversation_disabled: IStringTranslations,
        user_banned: IStringTranslations,
    },
    contact: {
        banned: IStringTranslations,
        blocked_times: IStringTranslations,
    }
}
export const translations: IObjectTranslations = {
    panel: {
        search: {
            'en': "Search",
            'de': "Search",
            'es': "Search",
            'it': "Search",
            'fr': "Search",
        },
        show_hidden_conv: {
            'en': "Show hidden conversations",
            'de': "Show hidden conversations",
            'es': "Show hidden conversations",
            'it': "Show hidden conversations",
            'fr': "Show hidden conversations",
        }
    },
    chat: {
        deleted_message: {
            'en': "Deleted message",
            'de': "Deleted message",
            'es': "Deleted message",
            'it': "Deleted message",
            'fr': "Deleted message",
        },
        blocked: {
            'en': "Conversation blocked",
            'de': "Conversation blocked",
            'es': "Conversation blocked",
            'it': "Conversation blocked",
            'fr': "Conversation blocked",
        }
    },
    menu: {
        pin: {
            'en': "pin",
            'de': "pin",
            'es': "pin",
            'it': "pin",
            'fr': "pin",
        },
        unpin: {
            'en': "unpin",
            'de': "unpin",
            'es': "unpin",
            'it': "unpin",
            'fr': "unpin",
        },
        hide: {
            'en': "hide",
            'de': "hide",
            'es': "hide",
            'it': "hide",
            'fr': "hide",
        },
        unhide: {
            'en': "show",
            'de': "show",
            'es': "show",
            'it': "show",
            'fr': "show",
        },
        ban: {
            'en': "ban",
            'de': "ban",
            'es': "ban",
            'it': "ban",
            'fr': "ban",
        },
        unban: {
            'en': "unban",
            'de': "unban",
            'es': "unban",
            'it': "unban",
            'fr': "unban",
        },
        block: {
            'en': "block user",
            'de': "block user",
            'es': "block user",
            'it': "block user",
            'fr': "block user",
        },
        unblock: {
            'en': "unblock user",
            'de': "unblock user",
            'es': "unblock user",
            'it': "unblock user",
            'fr': "unblock user",
        }
    },
    input_box_disabled: {
        me: {
            'en': "Your private messages are disabled, if you want to change this setting click on \"Edit my Forum info\"",
            'de': "Your private messages are disabled, if you want to change this setting click on \"Edit my Forum info\"",
            'es': "Your private messages are disabled, if you want to change this setting click on \"Edit my Forum info\"",
            'it': "Your private messages are disabled, if you want to change this setting click on \"Edit my Forum info\"",
            'fr': "Your private messages are disabled, if you want to change this setting click on \"Edit my Forum info\"",
        },
        other: {
            'en': "Other user disabled private messages",
            'de': "Other user disabled private messages",
            'es': "Other user disabled private messages",
            'it': "Other user disabled private messages",
            'fr': "Other user disabled private messages",
        },
        conversation_disabled: {
            'en': "Conversation disabled",
            'de': "Conversation disabled",
            'es': "Conversation disabled",
            'it': "Conversation disabled",
            'fr': "Conversation disabled",
        },
        user_banned: {
            'en': "Other user is banned",
            'de': "Other user is banned",
            'es': "Other user is banned",
            'it': "Other user is banned",
            'fr': "Other user is banned",
        },
    },
    contact: {
        banned: {
            'en': "banned",
            'de': "banned",
            'es': "banned",
            'it': "banned",
            'fr': "banned",
        },
        blocked_times: {
            'en': "blocked # time(s)",
            'de': "blocked # time(s)",
            'es': "blocked # time(s)",
            'it': "blocked # time(s)",
            'fr': "blocked # time(s)",
        }
    }
}