import InfoCircle from '../Common/InfoCircle';
import './MessengerPanelHeader.scss'
import { Tabs } from '../../API/Enum';
import { useEffect } from 'react';

interface IProps {
    selectedTab: Tabs,
    setSelectedTab: Function,
    showContactsTab: boolean
}

const MessengerPanelHeader = ({ selectedTab, setSelectedTab, showContactsTab }: IProps) => {
    return (
        <div className="messenger-panel-header w-100 d-flex">
            <div className={`mt-auto mb-auto cursor-pointer d-flex flex-row w-50 p-1 ${selectedTab === Tabs.Chat ? " selected bg-color-panel" : "bg-color-header"}`} onClick={() => setSelectedTab(Tabs.Chat)}>
                <InfoCircle icon="faCommentAlt" className="bg-color-info-circle-header font-size-10" />
                <span className="mt-auto mb-auto font-weight-bold ms-2">CHAT</span>
            </div>
            { showContactsTab &&
                <div className={`mt-auto mb-auto cursor-pointer d-flex flex-row w-50 p-1 ${selectedTab === Tabs.Contacts ? " selected bg-color-panel" : " bg-color-header"}`} onClick={() => setSelectedTab(Tabs.Contacts)}>
                    <InfoCircle icon="faAddressBook" className="bg-color-info-circle-header font-size-10" />
                    <span className="mt-auto mb-auto font-weight-bold ms-2">CONTACTS</span>
                </div>
            }
        </div>
    )
}

export default MessengerPanelHeader;