import { MsnRes } from '../../API/ResponseModels/Messenger';
import { ResourcesRes } from '../../API/ResponseModels/Resources';
import InfoCircle from '../../components/Common/InfoCircle';

interface IProps {
    user: MsnRes.IUser,
    picture?: ResourcesRes.IBase64,
}

const ChatMessageReactionUsers = ({ user, picture }: IProps) => {

    return (
        <div className="align-items-center d-flex flex-row p-1" >
            <div className="position-relative">
                <InfoCircle picture={picture?.content} userName={`${user.userFirstName.charAt(0)}${user.userLastName.charAt(0)}`}
                    className="bg-color-info-circle-conversation flex-shrink-0" />
            </div>
            <div className="d-flex flex-column ms-2 w-100">
                <div className="d-flex flex-row font-size-9 font-weight-bold">
                    {user.userFirstName} {user.userLastName}
                </div>
            </div>
        </div >
    );
}

export default ChatMessageReactionUsers;